"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Enum for control elements sizes
 */
var ControlElementSize;
(function (ControlElementSize) {
    ControlElementSize[ControlElementSize["QTR_WIDTH"] = 0] = "QTR_WIDTH";
    ControlElementSize[ControlElementSize["HALF_WIDTH"] = 1] = "HALF_WIDTH";
    ControlElementSize[ControlElementSize["FULL_WIDTH"] = 2] = "FULL_WIDTH";
})(ControlElementSize = exports.ControlElementSize || (exports.ControlElementSize = {}));
var ViewerMode;
(function (ViewerMode) {
    ViewerMode["PORTRAIT"] = "portrait";
    ViewerMode["LANDSCAPE"] = "landscape";
})(ViewerMode = exports.ViewerMode || (exports.ViewerMode = {}));
/**
 * The lights events.
 */
var LightEvents;
(function (LightEvents) {
    LightEvents["ADD"] = "add";
    LightEvents["REMOVE"] = "removed";
    LightEvents["CLEAR_LIGHTS"] = "clear.lights";
    LightEvents["DROPDOWN_CUSTOM_UPDATE"] = "dropdown.customUpdate";
})(LightEvents = exports.LightEvents || (exports.LightEvents = {}));
;
/**
 * Settings groups enum.
 */
var SettingsGroups;
(function (SettingsGroups) {
    SettingsGroups[SettingsGroups["WORLD"] = 1] = "WORLD";
    SettingsGroups[SettingsGroups["LIGHTS"] = 2] = "LIGHTS";
    SettingsGroups[SettingsGroups["BACKGROUND"] = 3] = "BACKGROUND";
    SettingsGroups[SettingsGroups["CAMERA"] = 4] = "CAMERA";
})(SettingsGroups = exports.SettingsGroups || (exports.SettingsGroups = {}));
