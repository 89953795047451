"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CopyManager = /** @class */ (function () {
    function CopyManager() {
        // #region Properties (6)
        this._nanString = '___NaN___';
        this._negInfinity = '___NegInfinity___';
        this._posInfinity = '___PosInfinity___';
        this._replaceRegex = /\]$/;
        this._splitRegex = /[.[\]]+/;
        this._copyId = 0;
        // #endregion Private Methods (6)
    }
    // #endregion Properties (6)
    // #region Public Methods (3)
    /**
     * Copy the value.
     * @param { T } value - value to copy
     * @param { string[] } assign - in case if value is object, given properties get referenced instead of copied.
     * @return { T }
     */
    CopyManager.prototype.deepCopy = function (value, assign) {
        var _this = this;
        if (assign === void 0) { assign = []; }
        if (typeof value !== "object")
            return value;
        if (!Array.isArray(assign)) {
            assign = [assign];
        }
        if (assign.length === 0) {
            var weakMap_1 = new WeakMap();
            var copies_1 = {};
            var json = JSON.stringify(value, function (key, value) { return _this.deepCopyReplacer(key, value, weakMap_1, copies_1); });
            return JSON.parse(json, function (key, value) { return _this.deepCopyReviver(key, value, copies_1); });
        }
        // get list of paths to copy
        var paths = [];
        if (this.getPaths(value, paths, '', assign) || paths.length === 0) {
            //  o is a leaf, copy direct
            return this.deepCopy(value);
        }
        // traverse paths, deep copy except for property names contained in assign
        var co = {};
        var _loop_1 = function (p) {
            var v = this_1.getAtPath(value, p);
            // check if p should be assigned instead of copied
            var bCopy = assign.every(function (a) {
                if (p === a || p.endsWith('.' + a))
                    return false;
                return true;
            });
            if (bCopy) {
                // is v an array? (getPaths treats these as leafs)
                if (Array.isArray(v)) {
                    // recurse for elements of the array
                    var vo = v;
                    v = [];
                    for (var _i = 0, vo_1 = vo; _i < vo_1.length; _i++) {
                        var val = vo_1[_i];
                        v.push(this_1.deepCopy(val, assign));
                    }
                }
                else {
                    v = this_1.deepCopy(v);
                }
            }
            // force at path
            this_1.forceAtPath(co, p, v);
        };
        var this_1 = this;
        for (var _i = 0, paths_1 = paths; _i < paths_1.length; _i++) {
            var p = paths_1[_i];
            _loop_1(p);
        }
        return co;
    };
    /**
   * Replacer function for creating deep copies using JSON.parse and JSON.stringify
   * Replaces certain values which would otherwise get lost
   * @param {String} key - key to stored
   * @param {Object} value - value to be stored
   * @return {Object} Replaced or original value
   */
    CopyManager.prototype.deepCopyReplacer = function (key, value, weakmap, copies) {
        if (weakmap === void 0) { weakmap = null; }
        if (copies === void 0) { copies = null; }
        if (typeof (value) === 'number' && isNaN(value)) {
            return this._nanString;
        }
        else if (value === Number.POSITIVE_INFINITY) {
            return this._posInfinity;
        }
        else if (value === Number.NEGATIVE_INFINITY) {
            return this._negInfinity;
        }
        else if (weakmap !== null && typeof value === "object" && value) {
            // check for references, it if never occured it gets stored in weakmap as key, and copy id gets stored as value.
            if (!weakmap.has(value)) {
                var copyId = this.createCopyId();
                weakmap.set(value, copyId);
                copies[copyId] = value;
                return value;
            }
            else if (copies !== null && weakmap !== null) {
                var copyId = weakmap.get(value);
                return copyId;
            }
        }
        return value;
    };
    /**
   * Reviver function for creating deep copies using JSON.parse and JSON.stringify
   * Restores certain values which would otherwise get lost
   * @param {String} key - key to restored
   * @param {Object} value - value to be restored
   * @return {Object} Restored value
   */
    CopyManager.prototype.deepCopyReviver = function (key, value, copies) {
        if (copies === void 0) { copies = null; }
        if (typeof (value) === 'string') {
            if (value === this._nanString) {
                return Number.NaN;
            }
            else if (value === this._posInfinity) {
                return Number.POSITIVE_INFINITY;
            }
            else if (value === this._negInfinity) {
                return Number.NEGATIVE_INFINITY;
            }
            else if (copies !== null && copies[value]) {
                return copies[value];
            }
        }
        return value;
    };
    // #endregion Public Methods (3)
    // #region Private Methods (6)
    /**
     * Creates copy id.
     * @return { string }
     */
    CopyManager.prototype.createCopyId = function () {
        return "___Copy___:" + this._copyId++;
    };
    /**
   * Set a nested element of an object, potentially overwriting existing elements along the path
   * @param {Object} o - object to set nested element for
   * @param {String[]} pathArr - array of strings defining path into object
   * @param {*} v - new value to set
   * @return {*}   value of nested element, undefined if was not set
   */
    CopyManager.prototype.forceAt = function (o, pathArr, v) {
        if (o === undefined || typeof o !== 'object')
            return;
        var l = pathArr.pop();
        if (typeof l !== 'string')
            return;
        if (pathArr.length > 0) {
            // get or create object at given path, potentially overwriting existing members which are not objects
            o = pathArr.reduce(function (obj, key) { return (obj && obj[key] !== undefined && typeof obj[key] === 'object') ? obj[key] : obj[key] = {}; }, o);
        }
        o[l] = v;
        return v;
    };
    /**
   * Set a nested element of an object, potentially overwriting existing elements along the path
   * @param {Object} o - object to set nested element for
   * @param {String} path - path string
   * @param {*} v - new value to set
   * @return {*}   value of nested element, undefined if was not set
   */
    CopyManager.prototype.forceAtPath = function (obj, path, v) {
        var pathArr = path.replace(this._replaceRegex, '').split(this._splitRegex);
        return this.forceAt(obj, pathArr, v);
    };
    /**
   * Get a nested element of an object
   * @param {Object} o - object to get nested element of
   * @param {String[]} pathArr - array defining path into the object, may contain strings for accessing properties or numbers for accessing array elements
   * @return {*}   nested element, undefined if not found
   */
    CopyManager.prototype.getAt = function (o, pathArr) {
        return pathArr.reduce(function (obj, key) { return (obj && obj[key] !== undefined) ? obj[key] : undefined; }, o);
    };
    /**
   * Get a nested element of an object
   * @param {Object} obj - object to get nested element of
   * @param {String} path - path string
   * @return {*}   nested element, undefined if not found
   */
    CopyManager.prototype.getAtPath = function (obj, path) {
        var pathArr = path.replace(/\]$/, '').split(/[.[\]]+/);
        return this.getAt(obj, pathArr);
    };
    /**
   * Given an object, return all paths to nested own enumerable properties which are leafs or arrays
   * Does not traverse object
   * @param {Object} o - object to get paths for
   * @param {String[]} paths - list of paths to add to, pass an empty array for first call
   * @param {String} [prefix=''] - prefix to use for paths, leave undefined for first call
   * @param {String[]} [leafNames=[]] - optional array of property names at which to stop traversal
   * @param {Boolean} [includePrototype] - if true, also return paths to enumerable properties in the prototype chain
   * @return {Boolean}   true if o is a leaf and its path should be added, false otherwise
   */
    CopyManager.prototype.getPaths = function (obj, paths, prefix, leafNames, includePrototype) {
        var _this = this;
        if (paths === void 0) { paths = []; }
        if (prefix === void 0) { prefix = ''; }
        if (leafNames === void 0) { leafNames = []; }
        if (includePrototype === void 0) { includePrototype = false; }
        // input sanity check
        if (!Array.isArray(paths))
            paths = [];
        if (typeof prefix !== 'string')
            prefix = '';
        if (!Array.isArray(leafNames))
            leafNames = [];
        if (typeof includePrototype !== 'boolean')
            includePrototype = false;
        // check for array
        if (obj === undefined || obj === null || typeof obj !== 'object' || Array.isArray(obj))
            return true;
        // get own enumerable properties
        var properties;
        if (includePrototype) {
            properties = [];
            for (var p in obj) {
                properties.push(p);
            }
        }
        else {
            properties = Object.keys(obj);
        }
        if (properties.length === 0) {
            return true;
        }
        // iterate over props
        properties.forEach(function (property) {
            var pre = prefix.length === 0 ? property : prefix + '.' + property;
            if (leafNames.includes(property)) {
                paths.push(pre);
            }
            else if (_this.getPaths(obj[property], paths, pre, leafNames, includePrototype)) {
                paths.push(pre);
            }
        });
        // not a leaf
        return false;
    };
    return CopyManager;
}());
exports.CopyManagerInstance = new CopyManager();
