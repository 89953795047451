"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var sd_controls_constants_types_1 = require("../../constants/sd-controls.constants.types");
var sd_controls_setting_1 = require("./sd.controls.setting");
var interfaces_1 = require("../../../../../interfaces/api/apps/sd-controls/interfaces");
/**
 * Holds other settings properties.
 * This is main light accordion definition.
 */
var LightDefinitionSetting = /** @class */ (function (_super) {
    __extends(LightDefinitionSetting, _super);
    // #endregion Properties (3)
    // #region Constructors (1)
    function LightDefinitionSetting(api, controlsContext, settings, name, desc, groupId, orderId, lightDef) {
        var _this = _super.call(this, api, "light.definition", sd_controls_constants_types_1.TYPES.MULTIPLE, name, desc, groupId, orderId, null, true) || this;
        _this.controlsContext = controlsContext;
        _this.settings = settings;
        _this.lightDef = lightDef;
        // #region Properties (3)
        _this.isLightUpdateRegistered = false;
        _this.isSettingRemovable = true;
        _this.light = true;
        _this.controlsContext.controlsEvents.on(interfaces_1.LightEvents.ADD, function () { return _this.registerLightUpdate(); });
        return _this;
    }
    // #endregion Constructors (1)
    // #region Public Methods (3)
    LightDefinitionSetting.prototype.close = function () {
        this.getMenuElements().forEach(function (el) { return el.close(); });
    };
    LightDefinitionSetting.prototype.onClick = function () {
        this.controlsContext.shapeDiverDragControls.makeLightDraggable(this.lightDef.id);
        this.registerLightUpdate();
    };
    LightDefinitionSetting.prototype.remove = function () {
        this.api.scene.lights.removeLight(this.lightDef.id);
        this.controlsContext.controlsEvents.trigger(interfaces_1.LightEvents.REMOVE, { name: this.lightDef.id, setting: sd_controls_constants_types_1.TYPES.MULTIPLE });
        this.controlsContext.shapeDiverDragControls.clearOnLightUpdateEvents(this.lightDef.id);
    };
    // #endregion Public Methods (3)
    // #region Private Methods (3)
    LightDefinitionSetting.prototype.findChangedProperties = function (lightDef) {
        var _this = this;
        var result = [];
        Object.keys(lightDef.properties).forEach(function (key) {
            var prop = lightDef.properties[key];
            // check components for property if object
            if (typeof prop === "object") {
                var propKeys = Object.keys(prop);
                for (var i = 0; i < propKeys.length; i++) {
                    var propKey = propKeys[i];
                    if (_this.lightDef.properties[key] && prop[propKey] != _this.lightDef.properties[key][propKey]) {
                        result.push(key);
                        break;
                    }
                }
            }
            // just compare
            else {
                if (prop != _this.lightDef.properties[key]) {
                    result.push(key);
                }
            }
        });
        return result;
    };
    LightDefinitionSetting.prototype.registerLightUpdate = function () {
        var _this = this;
        if (!this.isLightUpdateRegistered) {
            this.controlsContext.shapeDiverDragControls.onLightUpdate(this.lightDef.id, function (lightDef) {
                _this.updateInputElements(lightDef);
                return lightDef;
            });
        }
        this.isLightUpdateRegistered = true;
    };
    LightDefinitionSetting.prototype.updateInputElements = function (lightDef) {
        var _this = this;
        this.findChangedProperties(lightDef).forEach(function (key) {
            var setting = _this.settings[key];
            _this.lightDef.properties[key] = lightDef.properties[key];
            // copy and update with light propertis
            setting.getMenuElements().forEach(function (el) {
                el.onSettingUpdate(lightDef.properties[key]);
            });
        });
    };
    return LightDefinitionSetting;
}(sd_controls_setting_1.Setting));
exports.LightDefinitionSetting = LightDefinitionSetting;
