"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var enums_1 = require("../enums/enums");
var BaseCustomComponent_1 = require("./BaseCustomComponent");
var interfaces_1 = require("../../../../interfaces/api/apps/sd-controls/interfaces");
var SliderCustomComponent = /** @class */ (function (_super) {
    __extends(SliderCustomComponent, _super);
    // #endregion Properties (8)
    // #region Constructors (1)
    function SliderCustomComponent(id, parameterDefinition, parent, domManager, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, enums_1.ControlParameterType.SLIDER, id, parameterDefinition, parent, domManager, editMode) || this;
        _this._maxVal = 100;
        _this._minVal = 0;
        _this._step = 1;
        _this._value = 0;
        _this._inputId = id + "-number";
        _this._inputRangeId = id + "-range";
        if (_this._parameterDefinition.min) {
            _this._minVal = _this._parameterDefinition.min;
        }
        if (_this._parameterDefinition.max) {
            _this._maxVal = _this._parameterDefinition.max;
        }
        if (_this._parameterDefinition.value) {
            _this._value = _this._parameterDefinition.value;
        }
        if (_this._parameterDefinition.step) {
            _this._step = _this._parameterDefinition.step;
        }
        if (_this._parameterDefinition.delay) {
            _this._delay = _this._parameterDefinition.delay;
        }
        _this.createElement();
        _this.createInputEvents();
        return _this;
    }
    Object.defineProperty(SliderCustomComponent.prototype, "inputContainer", {
        // #endregion Constructors (1)
        // #region Public Accessors (3)
        get: function () {
            return this._inputContainer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SliderCustomComponent.prototype, "inputRange", {
        get: function () {
            return this._inputRange;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SliderCustomComponent.prototype, "size", {
        get: function () {
            return interfaces_1.ControlElementSize.HALF_WIDTH;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (3)
    // #region Protected Methods (3)
    SliderCustomComponent.prototype.createInput = function () {
        this.createRangeInput();
        this.createNumberInput();
    };
    SliderCustomComponent.prototype.createInputEvents = function () {
        var event = this._domManager.isEventSupported("input", "input") ? "input" : "change";
        this._domManager.setDomEventListener(this._inputComponent, event, this._delay, this.update.bind(this));
        this._domManager.setDomEventListener(this._inputComponent, "focusout", this._delay, this.update.bind(this));
        this._domManager.setDomEventListener(this._inputComponent, "keyup", this._delay, this.updateKeyUp.bind(this));
        this._domManager.setDomEventListener(this._inputRange, "input", this._delay, this.update.bind(this));
    };
    SliderCustomComponent.prototype.update = function (v, event) {
        if (event.type === "focusout" && v == this._inputComponent.value)
            return;
        this._inputComponent.value = v;
        this._inputRange.value = v;
        this._parameterDefinition.update(v);
    };
    // #endregion Protected Methods (3)
    // #region Private Methods (3)
    SliderCustomComponent.prototype.createNumberInput = function () {
        this._inputComponent = this._domManager.createDomElement("input", this._inputId, this._inputContainer, ["uk-input", "uk-width-1-3", "uk-form-small", "sd-input-number"], {
            min: this._minVal,
            max: this._maxVal,
            step: this._step,
            type: "number",
            name: this._inputId,
            value: this._value || "0"
        });
    };
    SliderCustomComponent.prototype.createRangeInput = function () {
        this._inputRange = this._domManager.createDomElement("input", this._inputRangeId, this._inputContainer, ["uk-range", "uk-width-2-3", "uk-form-small", "sd-input-range"], {
            type: "range",
            name: this._inputRangeId,
            min: this._minVal,
            max: this._maxVal,
            step: this._step,
            value: this._value || "0",
            tabIndex: "-1"
        });
        // mobile styling
        if (this._domManager.userAgentIsMobileOrTabletDevice) {
            this._inputRange.classList.add("sd-input-range-mobile");
        }
        // leave a bit of padding for ios mobile devices due to swipe gesture
        if (this._domManager.userAgentIsIPadOrIPhone) {
            this._inputRange.classList.add("ios-padding-left");
        }
        else {
            this._inputRange.classList.add("uk-padding-remove-left");
        }
    };
    SliderCustomComponent.prototype.updateKeyUp = function (v, e) {
        if (e.which || e.keyCode) {
            if (e.which == 13 || e.keyCode == 13) {
                this.update(v, e);
            }
        }
    };
    return SliderCustomComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.SliderCustomComponent = SliderCustomComponent;
