let LightHandler = function (___settings, ___handlers) {
  const THREE = require('../../../externals/three'),
        GLOBAL_UTILS = require('../../../shared/util/GlobalUtils'),
        LightHandlerInterface = require('../../interfaces/handlers/LightHandlerInterface'),
        TO_TINY_COLOR = require('../../../shared/util/toTinyColor'),
        THREE_D_MANAGER_CONSTANTS = require('../ThreeDManagerConstants'),
        DEFAULT_LIGHTS = require('../defaults/DefaultLights'),
        LIGHT_TYPES =  DEFAULT_LIGHTS.lightTypes,
        LIGHT_INIT =  DEFAULT_LIGHTS.lightInit,
        LIGHT_UPDATE =  DEFAULT_LIGHTS.lightUpdate,
        _settings = ___settings.settings,
        _handlers = ___handlers;

  let that,
      _perspectiveLights = new THREE.Object3D(),
      _orthographicLights = new THREE.Object3D(),
      _lights = new THREE.Object3D(),
      _helper = new THREE.Object3D(),
      _sceneBS = new THREE.Sphere(),
      _forceShadowDisabling = false,
      _shadowsEnabled = ___settings.shadows,
      _active = true, 
      _currentLightScene = {},
      _defaultLightScenes = DEFAULT_LIGHTS.lightScenes,
      _customLightScenes = {},
      _lightProperties = DEFAULT_LIGHTS.lightProperties;

  /**
   * The setting for the name of the current light scene.
   * Check if it is a string, if it is a valid light scene name and if isn't already the light scene.
   * @param {module:LightApiInterface~LightApiInterface#LightSceneID|module:LightApiInterface~LightApiInterface#LIGHTSCENE} value 
   */
  let _lightSceneHook = function (value) {
    let scope = 'LightHandler.Hook->lightScene';
    if (!GLOBAL_UTILS.typeCheck(value, 'string', _handlers.threeDManager.warn, scope))
      return false;
    
    if(!_defaultLightScenes[value] && !_customLightScenes[value]) {
      _handlers.threeDManager.warn(scope, 'The provided light scene is not available.');
      return false;
    }
    if(_defaultLightScenes[value] === _currentLightScene.id || _customLightScenes[value] === _currentLightScene.id) {
      _handlers.threeDManager.warn(scope, 'The current light scene is already set to this value.');
      return false;
    }

    if(_currentLightScene.id !== value)
      that.setLightSceneFromID(value);
    return true;
  };

  /**
   * Internal setting to set the object in which the custom light scenes are stored.
   * @param {Object} value 
   */
  let _lightScenesHook = function (value) {
    if (!value) return false;
    _customLightScenes = value;
    return true;
  };

  /**
   * Settings toggle to show/hide the helpers.
   * @param {Boolean} value 
   */
  let _helperHook = function (value) {
    let scope = 'LightHandler.Hook->helper';
    if (!GLOBAL_UTILS.typeCheck(value, 'boolean', _handlers.threeDManager.warn, scope))
      return false;  

    _helper.visible = value;
    _handlers.renderingHandler.updateShadowMap();
    _handlers.renderingHandler.render();
    return true;
  };

  class LightHandler extends LightHandlerInterface {

    constructor() {
      super();
      that = this;

      _handlers.threeDManager.helpers.addSceneObject(_perspectiveLights);
      _perspectiveLights.add(_lights);
      _perspectiveLights.add(_helper);
      _perspectiveLights.visible = _handlers.threeDManager.getSetting('camera.type') == THREE_D_MANAGER_CONSTANTS.cameraViewTypes.PERSPECTIVE;
      _helper.visible = _settings.getSetting('helper');

      _handlers.threeDManager.helpers.addSceneObject(_orthographicLights);
      _orthographicLights.add(new THREE.AmbientLight(0xffffff, 1));
      _orthographicLights.visible = _handlers.threeDManager.getSetting('camera.type') != THREE_D_MANAGER_CONSTANTS.cameraViewTypes.PERSPECTIVE;

      _sceneBS.radius = 25.0;
    
      // load and set the default light scene
      _currentLightScene = JSON.parse(JSON.stringify(_defaultLightScenes['default']));

      for (let lightID in _currentLightScene.lights) 
        that.updateLight(_currentLightScene.lights[lightID]);

      _currentLightScene.wasUpdated = false;
      _currentLightScene.id = 'default';

      _settings.registerHook('lightScene', _lightSceneHook);
      _settings.registerHook('lightScenes', _lightScenesHook);
      _settings.registerHook('helper', _helperHook);
    }


    ////////////
    ////////////
    //
    // LightHandler API
    //
    ////////////
    ////////////

    /** @inheritdoc */
    pause() {
      _active = false;
    }

    /** @inheritdoc */
    resume() {
      _active = true;
    }

    /** @inheritdoc */
    destroy() {
      // destroy the light handler
    }
    
    /**
     * Make a copy of this light scene and remove internal properties to make it ready for return.
     * 
     * @param {Object} lightScene 
     */
    _getCleanLightScene(lightScene) {
      let cleanedLightScene = JSON.parse(JSON.stringify(lightScene));
      delete cleanedLightScene.wasUpdated;

      for(let lightID in cleanedLightScene.lights) {
        delete cleanedLightScene.lights[lightID].lightObject;
        delete cleanedLightScene.lights[lightID].wasUpdated;
      }
      return cleanedLightScene;
    }

    /**
     * Depending on the existance of the light, create or update the light.
     * 
     * @param {Object} lightDefinition the definition of the light
     * @param {Object} lightObject an object that contains the actual light and the helper for it
     */
    _updateLightObject(lightDefinition, lightObject) {
      if(!lightObject) {
        if (lightDefinition.type === LIGHT_TYPES.DIRECTIONAL) {
          lightObject = LIGHT_INIT[LIGHT_TYPES.DIRECTIONAL](lightDefinition, _lights, _helper, _sceneBS, _forceShadowDisabling, _shadowsEnabled);
        } else if (lightDefinition.type === LIGHT_TYPES.AMBIENT) {
          lightObject = LIGHT_INIT[LIGHT_TYPES.AMBIENT](lightDefinition, _lights, _helper);
        } else if (lightDefinition.type === LIGHT_TYPES.HEMISPHERE) {
          lightObject = LIGHT_INIT[LIGHT_TYPES.HEMISPHERE](lightDefinition, _lights, _helper);
        } else if (lightDefinition.type === LIGHT_TYPES.POINT) {            
          lightObject = LIGHT_INIT[LIGHT_TYPES.POINT](lightDefinition, _lights, _helper, _sceneBS);
        // #SS-1113
        // } else if (lightDefinition.type === LIGHT_TYPES.RECTAREA) { 
        //  lightObject = LIGHT_INIT[LIGHT_TYPES.RECTAREA](lightDefinition, _lights, _helper);
        // 
        } else if (lightDefinition.type === LIGHT_TYPES.SPOT) {
          lightObject = LIGHT_INIT[LIGHT_TYPES.SPOT](lightDefinition, _lights, _helper);
        } else if (lightDefinition.type === LIGHT_TYPES.FLASH) {
          lightObject = LIGHT_INIT[LIGHT_TYPES.FLASH](lightDefinition, _handlers, _helper);
          lightObject.visible = _handlers.threeDManager.getSetting('camera.type') == THREE_D_MANAGER_CONSTANTS.cameraViewTypes.PERSPECTIVE;
        } 

        lightDefinition.lightObject = lightObject;
      } else {
        if (lightDefinition.type === LIGHT_TYPES.DIRECTIONAL) {
          LIGHT_UPDATE[LIGHT_TYPES.DIRECTIONAL](lightDefinition, lightObject, _sceneBS, _forceShadowDisabling, _shadowsEnabled);
        } else if (lightDefinition.type === LIGHT_TYPES.AMBIENT) {
          LIGHT_UPDATE[LIGHT_TYPES.AMBIENT](lightDefinition, lightObject);
        } else if (lightDefinition.type === LIGHT_TYPES.HEMISPHERE) {
          LIGHT_UPDATE[LIGHT_TYPES.HEMISPHERE](lightDefinition, lightObject);
        } else if (lightDefinition.type === LIGHT_TYPES.POINT) {
          LIGHT_UPDATE[LIGHT_TYPES.POINT](lightDefinition, lightObject);
        // #SS-1113
        // } else if (lightDefinition.type === LIGHT_TYPES.RECTAREA) {
        //  LIGHT_UPDATE[LIGHT_TYPES.RECTAREA](lightDefinition, lightObject);
        // 
        } else if (lightDefinition.type === LIGHT_TYPES.SPOT) {
          LIGHT_UPDATE[LIGHT_TYPES.SPOT](lightDefinition, lightObject);
        } else if (lightDefinition.type === LIGHT_TYPES.FLASH) {
          LIGHT_UPDATE[LIGHT_TYPES.FLASH](lightDefinition, lightObject);
        } 
      }
    }

    /**
     * Create a default lifht definition for a specific type.
     * 
     * @param {String} type the light type
     */
    _createDefault(type) {
      let p = {};
      for (let k in _lightProperties[type])
        p[k] = _lightProperties[type][k].default;

      if(type === LIGHT_TYPES.SPOT){
        let position = _sceneBS.center.clone().add(new THREE.Vector3(1,0,1).normalize().multiplyScalar(_sceneBS.radius * 2.0));
        p.position = { x: position.x, y: position.y, z: position.z };
        p.target = { x: _sceneBS.center.x, y: _sceneBS.center.y, z: _sceneBS.center.z };
      } else if (type === LIGHT_TYPES.POINT) {
        let position = _sceneBS.center.clone().add(new THREE.Vector3(0,0,1).normalize().multiplyScalar(_sceneBS.radius));
        p.position = { x: position.x, y: position.y, z: position.z };
      }
      return p;
    }

    /**
     * Go through the properties and set them in the light definition if:
     * 1. they exist
     * 2. the type is correct
     * 
     * @param {String} type the light type
     * @param {Object} properties the properties to be set
     * @param {Obejct} lightDefinition the light definition
     */
    _evaluateProperties(type, properties, lightDefinition) {
      for (let k in lightDefinition.properties) {
        if (properties[k] === undefined || properties[k] === null) continue;
        if (!_lightProperties[type][k]) continue;
        if (!GLOBAL_UTILS.typeCheck(properties[k], _lightProperties[type][k].type)) continue;
        lightDefinition.properties[k] = properties[k];
      }
      return lightDefinition;
    }

    /**
     * Go through the incoming definition and make it corresponding to our standards.
     * Check the id and create a random one if needed.
     * Create a new light definition if there isn't one and assign the default values to it.
     * Then evaluate the properties.
     * 
     * @param {Object} definition the incoming definition
     * @param {Object} lightDefinition the current light definition if there was one
     */
    _parseLightDefinition(definition, lightDefinition) {
      if (!(definition.id && GLOBAL_UTILS.typeCheck(definition.id, 'string'))){
        definition.id = Object.keys(_customLightScenes).length === 0 ? 'scene' : 'scene_' + Object.keys(_customLightScenes).length;
        let counter = Object.keys(_customLightScenes).length;
        while(_customLightScenes[definition.id])
          definition.id = 'scene_' + counter++;
      }
      if (!definition.type || Object.values(LIGHT_TYPES).indexOf(definition.type) === -1)
        return null;

      if (!lightDefinition) {
        lightDefinition = {};
        lightDefinition.id = definition.id;
        lightDefinition.type = definition.type;
        lightDefinition.properties = that._createDefault(definition.type);
      }

      return that._evaluateProperties(definition.type, definition.properties, lightDefinition);
    }

    /** @inheritdoc */
    updateLight(definition) {
      let lightDefinition = that._parseLightDefinition(definition, (definition.id && GLOBAL_UTILS.typeCheck(definition.id, 'string')) ? _currentLightScene.lights[definition.id] : null);
      if (!lightDefinition) return false;
      if (lightDefinition.type !== definition.type) return false;

      that._updateLightObject(lightDefinition, lightDefinition.lightObject);

      _currentLightScene.lights[lightDefinition.id] = lightDefinition;

      if (!_currentLightScene.wasUpdated) {
        _currentLightScene.wasUpdated = true;
        _currentLightScene.id = Object.keys(_customLightScenes).length === 0 ? 'scene' : 'scene_' + Object.keys(_customLightScenes).length;
        let counter = Object.keys(_customLightScenes).length;
        while(_customLightScenes[_currentLightScene.id])
          _currentLightScene.id = 'scene_' + counter++;
      }

      _handlers.renderingHandler.updateShadowMap();
      _handlers.renderingHandler.render();

      return true;
    }

    /** @inheritdoc */
    removeLight(id) {
      if (id && GLOBAL_UTILS.typeCheck(id, 'string')) {
        _helper.remove(_currentLightScene.lights[id].lightObject.helper);
        if(_currentLightScene.lights[id].lightObject.light.target)
          _lights.remove(_currentLightScene.lights[id].lightObject.light.target);
        _lights.remove(_currentLightScene.lights[id].lightObject.light);
        if (_currentLightScene.lights[id].type === LIGHT_TYPES.FLASH) {
          _handlers.cameraHandler.camera.remove(_currentLightScene.lights[id].lightObject.light);
        } else {
          _lights.remove(_currentLightScene.lights[id].lightObject.light);
        }
        delete _currentLightScene.lights[id].lightObject;
        delete _currentLightScene.lights[id];


        if (!_currentLightScene.wasUpdated) {
          _currentLightScene.wasUpdated = true;
          _currentLightScene.id = GLOBAL_UTILS.createRandomId();
        }
        
        _handlers.renderingHandler.updateShadowMap();
        _handlers.renderingHandler.render();
        return true;
      }
      return false;
    }

    /** @inheritdoc */
    getLight(id) {
      let s = that._getCleanLightScene(_currentLightScene);
      return s.lights[id];
    }

    /** @inheritdoc */
    setLightSceneFromID(id) {
      if (id && GLOBAL_UTILS.typeCheck(id, 'string') && (_defaultLightScenes[id] || _customLightScenes[id])) {
        for (let lightID in _currentLightScene.lights) 
          that.removeLight(lightID);

        let newLightScene;
        if (_defaultLightScenes[id]) {
          newLightScene = JSON.parse(JSON.stringify(_defaultLightScenes[id]));
        } else if (_customLightScenes[id]) {
          newLightScene = JSON.parse(JSON.stringify(_customLightScenes[id]));
        }

        for (let lightID in newLightScene.lights) 
          that.updateLight(newLightScene.lights[lightID]);

        _currentLightScene.id = newLightScene.id;
        _currentLightScene.wasUpdated = false;

        _settings.updateSetting('lightScene', id);
        return true;
      } else {
        return false;
      }
    }

    /** @inheritdoc */
    setLightSceneFromDefinition(lightScene) {
      if (!(lightScene.id && GLOBAL_UTILS.typeCheck(lightScene.id, 'string')) || _defaultLightScenes[lightScene.id])
        lightScene.id = GLOBAL_UTILS.createRandomId();

      for (let lightID in _currentLightScene.lights) 
        that.removeLight(lightID);

      _currentLightScene = {
        id: lightScene.id,
        wasUpdated: false,
        lights: {}
      };

      for (let lightID in lightScene.lights)
        that.updateLight(lightScene.lights[lightID]);

      that.saveLightScene(lightScene.id);

      return _currentLightScene.id;
    }

    /** @inheritdoc */
    getLightScene(id) {
      if(!id) {
        return that._getCleanLightScene(_currentLightScene);
      } else if (_defaultLightScenes[id]) {
        return that._getCleanLightScene(_defaultLightScenes[id]);
      } else if (_customLightScenes[id]) {
        return that._getCleanLightScene(_customLightScenes[id]);
      }
    }

    /** @inheritdoc */
    getAllLightScenes() {
      return Object.keys(_defaultLightScenes).concat(Object.keys(_customLightScenes));
    }

    /** @inheritdoc */
    saveLightScene(id) {
      if (!(id && GLOBAL_UTILS.typeCheck(id, 'string')) || _defaultLightScenes[id] ) {

        id = Object.keys(_customLightScenes).length === 0 ? 'scene' : 'scene_' + Object.keys(_customLightScenes).length;
        let counter = Object.keys(_customLightScenes).length;
        while(_customLightScenes[id])
          id = 'scene_' + counter++;
      }

      _currentLightScene.id = id;
      _currentLightScene.wasUpdated = false;
      _customLightScenes[id] = that._getCleanLightScene(_currentLightScene);

      _settings.updateSetting('lightScenes', _customLightScenes);
      _settings.updateSetting('lightScene', id);
      return id;
    }

    /** @inheritdoc */
    adjustToBoundingSphere(bs) {
      _sceneBS = bs;

      for (let lightID in _currentLightScene.lights) {
        let light = _currentLightScene.lights[lightID].lightObject.light,
            helper = _currentLightScene.lights[lightID].lightObject.helper;
        
        if (light.isDirectionalLight) {
          light.position.set(bs.center.x + light.direction.x * bs.radius * 2.35, bs.center.y + light.direction.y * bs.radius * 2.35, bs.center.z + light.direction.z * bs.radius * 2.35);
          light.target.position.set(bs.center.x, bs.center.y, bs.center.z);

          helper.setDirection(light.direction.clone().multiplyScalar(-1));
          helper.position.set(light.position.x, light.position.y, light.position.z);
          helper.setLength(light.target.position.distanceTo(light.position));

          if (light.shadows) {
            light.shadow.camera.far = 8 * bs.radius;
            light.shadow.camera.right = 1.5 * bs.radius;
            light.shadow.camera.left = -1.5 * bs.radius;
            light.shadow.camera.top = 1.5 * bs.radius;
            light.shadow.camera.bottom = -1.5 * bs.radius;
            light.shadow.camera.updateProjectionMatrix();
          }
        // } else if (light.isRectAreaLight) {
        //   light.lookAt(bs.center.x, bs.center.y, bs.center.z);
        } else if (light.isPointLight) {
          helper.children[0].geometry.radius = _sceneBS.radius / 10;
        }
      }
      _handlers.renderingHandler.updateShadowMap();
    }

    /**
     * Switch between the light settings of  the perspective camera and serveral orthographic views
     *
     * @param  {module:ThreeDManagerConstants~CameraViewType} view
     * @return {Boolean} true if the light settings were changed succefully
     */
    adaptLightingToCameraType(type) {
      _perspectiveLights.visible = type == THREE_D_MANAGER_CONSTANTS.cameraViewTypes.PERSPECTIVE;
      _orthographicLights.visible = type != THREE_D_MANAGER_CONSTANTS.cameraViewTypes.PERSPECTIVE;
      for (let lightID in _currentLightScene.lights) {
        let lightDefinition = _currentLightScene.lights[lightID];
        if(lightDefinition.type === LIGHT_TYPES.FLASH)
          lightDefinition.lightObject.visible = type == THREE_D_MANAGER_CONSTANTS.cameraViewTypes.PERSPECTIVE;
      }
      return true;
    }

    /** @inheritdoc */
    setToggleLightShadows(toggle) {
      _shadowsEnabled = toggle;
      // No sanity check needed as this is done in RenderingHandler.Hook->shadows
      for (let i = 0; i < _lights.children.length; i++) {
        if (_lights.children[i].shadows)
          _lights.children[i].castShadow = _forceShadowDisabling ? false : _shadowsEnabled;
      }
    }

    shadowSettingOverride(toggle) {
      if (!_forceShadowDisabling) _forceShadowDisabling = toggle;
      if (_forceShadowDisabling) {
        _handlers.threeDManager.warn('LightHandler', 'Due to restrictions to the number of maps that can be used with this device, the shadows were disabled.');
        that.setToggleLightShadows();
      }
    }

    /**
     * Michael: this has to be redone as there were changes in the shader code #SS-1127
     * 
     * @param {*} count 
     */
    reevaluateNumberOfShadowMaps(count) {
      let materials = _handlers.threeDManager.helpers.getMaterials();
      let availableTextures = count;
      for (let i = 0, len = materials.length; i < len; i++) {
        let mat = materials[i];
        if (mat.availableTextures)
          availableTextures = Math.min(availableTextures, mat.availableTextures);
      }

      let currentShadows = 0;
      for (let i = 0; i < _lights.children.length; i++) {
        if (_lights.children[i].castShadow)
          currentShadows++;
      }

      that.shadowSettingOverride(currentShadows - 1 >= availableTextures);
    }

    /** @inheritdoc */
    toggleLights(toggle) {
      _lights.visible = toggle;
    }
  }

  return new LightHandler(___settings);
};

module.exports = LightHandler;
