"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var sd_controls_constants_types_1 = require("../../constants/sd-controls.constants.types");
var sd_controls_settings_html_helpers_1 = require("../sd-controls.settings.html-helpers");
var sd_controls_menu_element_factory_1 = require("../../components/util/sd-controls.menu-element.factory");
var sd_controls_setting_1 = require("./sd.controls.setting");
var interfaces_1 = require("../../../../../interfaces/api/apps/sd-controls/interfaces");
var AddLightModalSetting = /** @class */ (function (_super) {
    __extends(AddLightModalSetting, _super);
    // #endregion Properties (3)
    // #region Constructors (1)
    function AddLightModalSetting(api, controlsContext, settingsContext) {
        var _this = _super.call(this, api, 'scene.add.light', sd_controls_constants_types_1.TYPES.MODAL, "Add Light", "Define a new light source in the scene", interfaces_1.SettingsGroups.LIGHTS, Number.POSITIVE_INFINITY, null, true) || this;
        _this.controlsContext = controlsContext;
        _this.settingsContext = settingsContext;
        // #region Properties (3)
        _this.modalConfirmId = "jq-add-light-modal-confirm";
        _this.modalId = "jq-add-light-modal";
        _this.defval = false;
        _this.modalContent = _this.getModalString();
        return _this;
    }
    // #endregion Constructors (1)
    // #region Public Methods (2)
    AddLightModalSetting.prototype.customUpdate = function (id, v, event, menuEl) {
        var name = document.getElementById("jq-add-light-name").value;
        var alertHtml = document.getElementById("jq-add-light-alert");
        if (!name) {
            if (alertHtml) {
                alertHtml.innerHTML = sd_controls_settings_html_helpers_1.HTMLSettingsHelper.createAlert("Please specify a name for the new light");
            }
            return;
        }
        else {
            if (alertHtml) {
                alertHtml.innerHTML = "";
            }
        }
        var type = (document.getElementById("jq-add-light-type")).value;
        name = this.resolveNameIfDuplicate(name);
        var lightParams = {
            id: name,
            type: this.api.scene.lights.LIGHTTYPE[type],
            properties: {}
        };
        this.api.scene.lights.updateLight(lightParams);
        var light = this.api.scene.lights.getLight(name).data;
        var setting = this.settingsContext.createLightSetting(light);
        this.settingsContext.settings[light.id] = setting;
        var elem = sd_controls_menu_element_factory_1.createMenuElement(setting.type, setting, this.controlsContext, this.controlsContext.groups.getGroup(setting.groupId));
        elem.row.parentNode.insertBefore(elem.row, menuEl.row);
        this.controlsContext.controlsEvents.trigger(interfaces_1.LightEvents.ADD, elem);
        UIkit.modal(document.getElementById("jq-add-light-modal")).hide();
        // open accordion
        this.settingsContext.settings[light.id].getMenuElements().forEach(function (x) { return x.open(); });
        this.controlsContext.shapeDiverDragControls.makeLightDraggableAndZoomAsync(light.id);
        // add small timeout due to animation of modal closing
        window.setTimeout(this.setNameFieldAccordingToSelectedType.bind(this), 2000);
    };
    AddLightModalSetting.prototype.registerMenuElement = function (element) {
        _super.prototype.registerMenuElement.call(this, element);
        this.bindLightTypeChangeEvent();
    };
    // #endregion Public Methods (2)
    // #region Private Methods (3)
    AddLightModalSetting.prototype.setNameFieldAccordingToSelectedType = function () {
        var lightTypeElem = document.getElementById("jq-add-light-type");
        document.getElementById("jq-add-light-name").value = this.resolveNameIfDuplicate(lightTypeElem.value);
    };
    AddLightModalSetting.prototype.bindLightTypeChangeEvent = function () {
        var _this = this;
        document.getElementById("jq-add-light-type").onchange = function () { return _this.setNameFieldAccordingToSelectedType(); };
        this.setNameFieldAccordingToSelectedType();
    };
    AddLightModalSetting.prototype.getModalString = function () {
        return "\n    <div class=\"uk-modal-dialog uk-modal-body\">\n\n    <h2 class=\"uk-modal-title\">Add Light</h2>\n\n      <form class=\"uk-form-horizontal\" onsubmit=\"return false\"> \n        <div class=\"uk-margin\">\n          <label class=\"uk-form-label\" for=\"form-horizontal-select\">Type:</label>\n          <div class=\"uk-form-controls\">\n              <select class=\"uk-select\" id=\"jq-add-light-type\">\n                  " + Object.keys(this.api.scene.lights.LIGHTTYPE).map(function (x) { return "<option>" + x + "</option>"; }).join("") + "\n              </select>\n          </div>\n        </div>\n        <div class=\"uk-margin\">\n          <label class=\"uk-form-label\" for=\"form-horizontal-text\">Name:</label>\n          <div class=\"uk-form-controls\">\n              <input id=\"jq-add-light-name\" class=\"uk-input\" type=\"text\" placeholder=\"type name ...\">\n          </div>\n        </div>\n        <p class=\"uk-text-right\">\n          <button class=\"uk-button uk-button-default uk-modal-close\" type=\"button\">Cancel</button>\n          <input id=\"jq-add-light-modal-confirm\" class=\"uk-button uk-button-primary sd-control-button-confirm\" type=\"submit\" value=\"save\">\n          <div id=\"jq-add-light-alert\">\n\n          </div>\n        </p>\n      </form>\n    </div>";
    };
    AddLightModalSetting.prototype.resolveNameIfDuplicate = function (name) {
        var lightScene = this.api.scene.lights.getLightScene().data;
        // check for dupe id
        if (lightScene.lights[name]) {
            var dupeId = 1;
            while (this.settingsContext.settings[name + ("_" + dupeId)]) {
                dupeId++;
            }
            name += "_" + dupeId;
        }
        return name;
    };
    return AddLightModalSetting;
}(sd_controls_setting_1.Setting));
exports.AddLightModalSetting = AddLightModalSetting;
