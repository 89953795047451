"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var interfaces_1 = require("../../../../interfaces/api/apps/sd-controls/interfaces");
var BaseCustomComponent_1 = require("./BaseCustomComponent");
var enums_1 = require("../enums/enums");
var ButtonCustomComponent = /** @class */ (function (_super) {
    __extends(ButtonCustomComponent, _super);
    // #endregion Properties (1)
    // #region Constructors (1)
    function ButtonCustomComponent(id, parameterDefinition, parent, domManager, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, enums_1.ControlParameterType.BUTTON, id, parameterDefinition, parent, domManager, editMode) || this;
        _this._inputId = id + "-text";
        _this.createElement();
        _this.createInputEvents();
        return _this;
    }
    Object.defineProperty(ButtonCustomComponent.prototype, "size", {
        // #endregion Constructors (1)
        // #region Public Accessors (1)
        get: function () {
            return interfaces_1.ControlElementSize.QTR_WIDTH;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (1)
    // #region Protected Methods (3)
    ButtonCustomComponent.prototype.createInput = function () {
        this._row.removeChild(this._label);
        // create the button dom element
        var id = this.id + "-button";
        this._inputComponent = this._domManager.createDomElement("button", id, this._inputContainer, [
            'uk-button', 'uk-button-default', 'uk-button-small', 'uk-width-expand', 'text-transform-none', 'uk-padding-remove', 'sd-control-button'
        ], { type: 'button', name: id });
        this._inputComponent.innerHTML = this._parameterDefinition.name;
    };
    ButtonCustomComponent.prototype.createInputEvents = function () {
        this._domManager.setDomEventListener(this._inputComponent, 'click', 10, this.update.bind(this));
    };
    ButtonCustomComponent.prototype.update = function (v, event) {
        this._parameterDefinition.update(true);
    };
    return ButtonCustomComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.ButtonCustomComponent = ButtonCustomComponent;
