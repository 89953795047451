"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SDControlsSortManager = /** @class */ (function () {
    function SDControlsSortManager() {
    }
    // #region Public Methods (1)
    SDControlsSortManager.prototype.sort = function (elements) {
        if (elements.length > 0) {
            elements.sort(function (a, b) { return a.order - b.order; });
            var index = elements.length - 1;
            var current = elements[index];
            // single element case
            if (index === 0) {
                if (current.children && current.children.length > 0) {
                    this.sort(current.children);
                }
            }
            else {
                while (index > 0) {
                    if (current.children && current.children.length > 0) {
                        this.sort(current.children);
                    }
                    var next = elements[--index];
                    next.insertBefore(current);
                    current = next;
                }
            }
        }
    };
    return SDControlsSortManager;
}());
exports.SDControlsSortManagerInstance = new SDControlsSortManager();
