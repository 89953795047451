"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ICameraHandler_1 = require("../../interfaces/ICameraHandler");
var PerspectiveCameraHandler_1 = require("./PerspectiveCameraHandler");
var OrthographicCameraHandler_1 = require("./OrthographicCameraHandler");
var ProxyCameraHandler = /** @class */ (function () {
    // #endregion Properties (21)
    // #region Constructors (1)
    function ProxyCameraHandler(settings, _handlers) {
        var _this = this;
        this._handlers = _handlers;
        this.activate = function () { };
        this.adjustToBoundingSphere = function (bs) {
            _this._perspectiveCameraHandler.adjustToBoundingSphere(bs);
            _this._orthographicCameraHandler.adjustToBoundingSphere(bs);
        };
        this.deactivate = function () { };
        this.decreaseEventListenerCounter = function () { return _this._cameraHandler.decreaseEventListenerCounter(); };
        this.destroy = function () {
            _this._perspectiveCameraHandler.destroy();
            _this._orthographicCameraHandler.destroy();
        };
        this.getPositionAndTarget = function () { return _this._cameraHandler.getPositionAndTarget(); };
        this.getZoomExtentsPosition = function (bb) { return _this._cameraHandler.getZoomExtentsPosition(bb); };
        this.increaseEventListenerCounter = function () { return _this._cameraHandler.increaseEventListenerCounter(); };
        this.onResize = function (width, height) {
            _this._perspectiveCameraHandler.onResize(width, height);
            _this._orthographicCameraHandler.onResize(width, height);
        };
        this.pause = function () { return _this._cameraHandler.pause(); };
        this.resetPositionAndTarget = function (options) { return _this._cameraHandler.resetPositionAndTarget(options); };
        this.resume = function () { return _this._cameraHandler.resume(); };
        this.setCameraPath = function (path, options) { return _this._cameraHandler.setCameraPath(path, options); };
        this.setPositionAndTarget = function (position, target, options) { return _this._cameraHandler.setPositionAndTarget(position, target, options); };
        this.setViewAndProjectionMatrix = function (viewMatrix, projectionMatrix) { return _this._cameraHandler.setViewAndProjectionMatrix(viewMatrix, projectionMatrix); };
        this.updateCameraControls = function (time) { return _this._cameraHandler.updateCameraControls(time); };
        this.zoomExtents = function (options, scenePathsOrBB) { return _this._cameraHandler.zoomExtents(options, scenePathsOrBB); };
        this._settings = settings.settings;
        this._perspectiveCameraHandler = new PerspectiveCameraHandler_1.PerspectiveCameraHandler(settings, this._handlers);
        this._orthographicCameraHandler = new OrthographicCameraHandler_1.OrthographicCameraHandler(settings, this._handlers);
        this._cameraHandler = this._perspectiveCameraHandler;
        this._perspectiveCameraHandler.resume();
        this._orthographicCameraHandler.pause();
        this._settings.registerNotifier('cameraTypes.active', function () {
            _this._cameraHandler.deactivate();
            _this._cameraHandler = _this._settings.getSetting('cameraTypes.active') === ICameraHandler_1.CameraType.PERSPECTIVE ? _this._perspectiveCameraHandler : _this._orthographicCameraHandler;
            _this._cameraHandler.activate();
            _this._handlers.renderingHandler.render();
            return true;
        });
    }
    Object.defineProperty(ProxyCameraHandler.prototype, "camera", {
        // #endregion Constructors (1)
        // #region Public Accessors (1)
        get: function () { return this._cameraHandler.camera; },
        enumerable: true,
        configurable: true
    });
    return ProxyCameraHandler;
}());
exports.ProxyCameraHandler = ProxyCameraHandler;
