"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseCameraHandler_1 = require("./BaseCameraHandler");
var THREE = require("../../../../externals/three");
var HooksAndNotifiers_1 = require("../../helpers/HooksAndNotifiers");
var OrthographicCameraHooksAndNotifiers_1 = require("./hooks/OrthographicCameraHooksAndNotifiers");
var CameraControls_1 = require("./controls/CameraControls");
var OrthographicManager_1 = require("./controls/orthographic/OrthographicManager");
var ICameraHandler_1 = require("../../interfaces/ICameraHandler");
var OrthographicCameraHandler = /** @class */ (function (_super) {
    __extends(OrthographicCameraHandler, _super);
    // #endregion Properties (2)
    // #region Constructors (1)
    function OrthographicCameraHandler(settings, handlers) {
        var _this = _super.call(this, settings, handlers) || this;
        // #region Properties (2)
        _this._defaultSettings = {
            near: 0.01,
            far: 10000
        };
        _this._orthographicSettings = _this._settings.getSection('cameraTypes.orthographic');
        _this._camera = new THREE.OrthographicCamera(settings.container.offsetWidth / settings.container.offsetHeight, settings.container.offsetWidth / settings.container.offsetHeight, 1, -1, _this._defaultSettings.near, _this._defaultSettings.far);
        _this._camera.up.set(0, 0, 1);
        _this._camera.aspect = settings.container.offsetWidth / settings.container.offsetHeight;
        _this._handlers.threeDManager.helpers.addSceneObject(_this._camera);
        var defaults = _this._orthographicSettings.getSetting('default');
        if (defaults.position.x === 0 && defaults.position.y === 0 && defaults.position.z === 0 &&
            defaults.target.x === 0 && defaults.target.y === 0 && defaults.target.z === 0)
            defaults = _this.getZoomExtentsPosition();
        _this._cameraControls = new CameraControls_1.CameraControls(_this._camera, defaults, _this._handlers.renderingHandler.getDomElement(), function () {
            _this._handlers.renderingHandler.registerForContinuousRendering(_this.CAMERA_MOVING_ID);
        }, _this.cameraMovementEventDispatch.bind(_this), function () {
            var distance = _this._cameraControls.position.distanceTo(_this._cameraControls.target) / 2;
            _this._camera.left = -distance * _this._camera.aspect;
            _this._camera.bottom = -distance;
            _this._camera.right = distance * _this._camera.aspect;
            _this._camera.top = distance;
            _this._camera.near = _this._defaultSettings.near * distance;
            _this._camera.far = _this._defaultSettings.far * distance;
            _this._camera.updateProjectionMatrix();
        });
        _this._cameraControlsManager = new OrthographicManager_1.OrthographicManager(_this._settings.getSection('controls.orthographic'), _this._cameraControls, _this._handlers.renderingHandler.render, _this._handlers.threeDManager.warn);
        HooksAndNotifiers_1.registerHooksAndNotifiers(_this, _this._orthographicSettings, OrthographicCameraHooksAndNotifiers_1.OrthographicCameraHooks, OrthographicCameraHooksAndNotifiers_1.OrthographicCameraNotifiers);
        return _this;
    }
    // #endregion Constructors (1)
    // #region Public Methods (9)
    OrthographicCameraHandler.prototype.activate = function () {
        this.resume();
        this.zoomExtents({ duration: 0 });
        this._camera.updateProjectionMatrix();
        this._handlers.materialHandler.setSceneBackground(null);
    };
    OrthographicCameraHandler.prototype.adjustToBoundingSphere = function (bs) {
        this._sceneBS = bs;
        var distance = this._cameraControls.position.distanceTo(this._cameraControls.target) / 2;
        this._camera.near = this._defaultSettings.near * distance;
        this._camera.far = this._defaultSettings.far * distance;
        this._camera.updateProjectionMatrix();
    };
    OrthographicCameraHandler.prototype.deactivate = function () {
        this.pause();
    };
    OrthographicCameraHandler.prototype.getZoomExtentsPosition = function (bb) {
        var bs = this._sceneBS;
        if (bb)
            bb.getBoundingSphere(bs);
        var factor = 2 * bs.radius * this._settings.getSetting('zoomExtentsFactor');
        var position = new THREE.Vector3(), target = new THREE.Vector3();
        switch (this._settings.getSetting('cameraTypes.active')) {
            case ICameraHandler_1.CameraType.TOP:
                position.set(bs.center.x, bs.center.y, bs.center.z + factor);
                break;
            case ICameraHandler_1.CameraType.BOTTOM:
                position.set(bs.center.x, bs.center.y, bs.center.z - factor);
                break;
            case ICameraHandler_1.CameraType.RIGHT:
                position.set(bs.center.x + factor, bs.center.y, bs.center.z);
                break;
            case ICameraHandler_1.CameraType.LEFT:
                position.set(bs.center.x - factor, bs.center.y, bs.center.z);
                break;
            case ICameraHandler_1.CameraType.BACK:
                position.set(bs.center.x, bs.center.y + factor, bs.center.z);
                break;
            case ICameraHandler_1.CameraType.FRONT:
                position.set(bs.center.x, bs.center.y - factor, bs.center.z);
                break;
            default:
                position.set(bs.center.x, bs.center.y, bs.center.z + factor);
                break;
        }
        target.set(bs.center.x, bs.center.y, bs.center.z);
        return {
            position: position,
            target: target
        };
    };
    OrthographicCameraHandler.prototype.onResize = function (width, height) {
        this._camera.aspect = width / height;
        this._camera.left = -this._sceneBS.radius * this._camera.aspect;
        this._camera.right = this._sceneBS.radius * this._camera.aspect;
        this._camera.updateProjectionMatrix();
    };
    OrthographicCameraHandler.prototype.resetPositionAndTarget = function (options) {
        var defaults = this._orthographicSettings.getSetting('default');
        if (defaults.position.x === 0 && defaults.position.y === 0 && defaults.position.z === 0 &&
            defaults.target.x === 0 && defaults.target.y === 0 && defaults.target.z === 0)
            defaults = this.getZoomExtentsPosition();
        return this.setPositionAndTarget(defaults.position, defaults.target, options);
    };
    OrthographicCameraHandler.prototype.setCameraPath = function (path, options) {
        var scope = 'OrthographicCameraHandler.setCameraPath';
        if (!path)
            return Promise.resolve(false);
        if (!Array.isArray(path))
            return Promise.resolve(false);
        if (path.length === 0)
            return Promise.resolve(false);
        if (!options)
            options = {};
        options.duration = options.duration >= 0 ? options.duration : this._settings.getSetting('cameraMovementDuration');
        var adjustedPath = [];
        // check input sanity
        for (var _i = 0, path_1 = path; _i < path_1.length; _i++) {
            var point = path_1[_i];
            if (!this._globalUtils.typeCheck(point.position, 'vector3any', this._handlers.threeDManager.warn, scope))
                return Promise.resolve(false);
            if (!this._globalUtils.typeCheck(point.target, 'vector3any', this._handlers.threeDManager.warn, scope))
                return Promise.resolve(false);
            var position = this._globalUtils.toVector3(point.position);
            var target = this._globalUtils.toVector3(point.target);
            if (adjustedPath.length + 1 === path.length) {
                if (!this._cameraControls.isWithinRestrictions(position, target))
                    return Promise.resolve(false);
                if (options.default) {
                    this._orthographicSettings.updateSetting('default.position', position);
                    this._orthographicSettings.updateSetting('default.target', target);
                }
            }
            target = this.convertTargetForCurrentSide(position, target, position.distanceTo(target) / 2, this._settings.getSetting('cameraTypes.active'));
            adjustedPath.push({ position: position, target: target });
        }
        if (this._firstTimeVisible.resolved === false)
            options.duration = 0;
        return this._cameraControls.animate(adjustedPath, options);
    };
    OrthographicCameraHandler.prototype.setPositionAndTarget = function (position, target, options) {
        var scope = 'OrthographicCameraHandler.setPositionAndTarget';
        if (!(this._globalUtils.typeCheck(position, 'vector3any', this._handlers.threeDManager.warn, scope) &&
            this._globalUtils.typeCheck(target, 'vector3any', this._handlers.threeDManager.warn, scope)))
            return Promise.resolve(false);
        // convert input to THREE.Vector3
        var position_to = this._globalUtils.toVector3(position);
        var target_to = this._globalUtils.toVector3(target);
        if (!options)
            options = {};
        options.duration = options.duration >= 0 ? options.duration : this._settings.getSetting('cameraMovementDuration');
        options.coordinates = 'Linear';
        if (!this._cameraControls.isWithinRestrictions(position_to, target_to))
            return Promise.resolve(false);
        if (options.default) {
            this._orthographicSettings.updateSetting('default.position', position_to);
            this._orthographicSettings.updateSetting('default.target', target_to);
        }
        target_to = this.convertTargetForCurrentSide(position_to, target_to, position_to.distanceTo(target_to) / 2, this._settings.getSetting('cameraTypes.active'));
        var tActive = this._settings.getSetting('cameraTypes.active');
        this._camera.up.set(0, tActive === ICameraHandler_1.CameraType.TOP || tActive === ICameraHandler_1.CameraType.BOTTOM ? 1 : 0, tActive === ICameraHandler_1.CameraType.TOP || tActive === ICameraHandler_1.CameraType.BOTTOM ? 0 : 1);
        this._camera.updateProjectionMatrix();
        if (this._firstTimeVisible.resolved === false)
            options.duration = 0;
        return this._cameraControls.animate([
            { position: this._cameraControls.position.clone(), target: this._cameraControls.target.clone() },
            { position: position_to, target: target_to }
        ], options);
    };
    OrthographicCameraHandler.prototype.zoomExtents = function (options, scenePathsOrBB) {
        var bb = scenePathsOrBB ? scenePathsOrBB instanceof THREE.Box3 ? scenePathsOrBB : this._globalUtils.typeCheck(scenePathsOrBB, 'box3any') ? new THREE.Box3(this._globalUtils.toVector3(scenePathsOrBB.min), this._globalUtils.toVector3(scenePathsOrBB.max)) : this._handlers.threeDManager.computeBoundingBox(scenePathsOrBB) : this._handlers.threeDManager.computeBoundingBox();
        var _a = this.getZoomExtentsPosition(bb), position = _a.position, target = _a.target;
        return this.setPositionAndTarget(position, target, options);
    };
    // #endregion Public Methods (9)
    // #region Private Methods (1)
    OrthographicCameraHandler.prototype.convertTargetForCurrentSide = function (position, target, distance, side) {
        // target adjustement
        // as only sides are available, adjust the target to the type
        switch (side) {
            case ICameraHandler_1.CameraType.TOP:
                target.set(position.x, position.y, position.z - 2 * distance);
                break;
            case ICameraHandler_1.CameraType.BOTTOM:
                target.set(position.x, position.y, position.z + 2 * distance);
                break;
            case ICameraHandler_1.CameraType.RIGHT:
                target.set(position.x - 2 * distance, position.y, position.z);
                break;
            case ICameraHandler_1.CameraType.LEFT:
                target.set(position.x + 2 * distance, position.y, position.z);
                break;
            case ICameraHandler_1.CameraType.BACK:
                target.set(position.x, position.y - 2 * distance, position.z);
                break;
            case ICameraHandler_1.CameraType.FRONT:
                target.set(position.x, position.y + 2 * distance, position.z);
                break;
            default:
                target.set(position.x, position.y, position.z - 2 * distance);
                break;
        }
        return target;
    };
    return OrthographicCameraHandler;
}(BaseCameraHandler_1.BaseCameraHandler));
exports.OrthographicCameraHandler = OrthographicCameraHandler;
