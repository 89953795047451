"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseCustomComponent_1 = require("./BaseCustomComponent");
var enums_1 = require("../enums/enums");
var interfaces_1 = require("../../../../interfaces/api/apps/sd-controls/interfaces");
var GroupCustomComponent = /** @class */ (function (_super) {
    __extends(GroupCustomComponent, _super);
    // #endregion Properties (3)
    // #region Constructors (1)
    function GroupCustomComponent(id, parameterDefinition, parent, domManager, _controlsFactory, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, enums_1.ControlParameterType.GROUP, id, parameterDefinition, parent, domManager, editMode) || this;
        _this._controlsFactory = _controlsFactory;
        // #region Properties (3)
        _this._accordionType = "custom-parameter";
        _this._rowId = _this.id + "-controlmenu-row";
        _this.createElement();
        _this.createInputEvents();
        return _this;
    }
    Object.defineProperty(GroupCustomComponent.prototype, "size", {
        // #endregion Constructors (1)
        // #region Public Accessors (1)
        get: function () {
            return interfaces_1.ControlElementSize.FULL_WIDTH;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (1)
    // #region Protected Methods (5)
    GroupCustomComponent.prototype.createInput = function () {
        var _this = this;
        this._inputComponent = this._domManager.createDomElement("div", null, this._parametersEl, ["uk-accordion-content", "uk-margin-remove"], { "uk-grid": "" });
        this._parameterDefinition.parameters.forEach(function (x) {
            var customComponent = _this._controlsFactory.createUserDefinedParameter(x, _this._inputComponent, false);
            _this.children.push(customComponent);
            if (customComponent.label) {
                customComponent.label.classList.remove("uk-text-uppercase");
            }
        });
    };
    GroupCustomComponent.prototype.createInputEvents = function () {
        this._domManager.setDomEventListener(this._ulElem, "click", 10, this.closeOthers.bind(this));
        this._domManager.setDomEventListener(this._ulElem, "click", 10, this.update.bind(this));
    };
    GroupCustomComponent.prototype.createLabel = function () {
        this._label = this._domManager.createDomElement("a", this.id + "-a", this._parametersEl, [
            "uk-accordion-title",
            "uk-width-expand",
            "uk-form-label",
            "uk-text-uppercase",
            "uk-text-small",
            "uk-padding-small",
            "sdv-control-panel-row-border"
        ]);
        this._label.innerHTML = this._parameterDefinition.name;
    };
    GroupCustomComponent.prototype.createRow = function () {
        this._row = this._domManager.createDomElement("row", this._rowId, this._parent, ["uk-margin-remove", "uk-padding-remove", "sd-max-width-1"], { "uk-grid": 'margin: "";' });
        this._ulElem = this._domManager.createDomElement("ul", null, this._row, ["uk-width-1-1"], { "uk-accordion": "multiple: false" });
        this._parametersEl = this._domManager.createDomElement("li", null, this._ulElem, ["jq-accordion-control-li"], { "data-setting-type": this._accordionType });
    };
    GroupCustomComponent.prototype.update = function (v, event) {
    };
    // #endregion Protected Methods (5)
    // #region Private Methods (1)
    GroupCustomComponent.prototype.closeOthers = function () {
        var _this = this;
        Array.from(document.getElementsByClassName("jq-accordion-control-li")).forEach(function (elem) {
            var type = elem.getAttribute("data-setting-type");
            if (type == _this._accordionType && !_this._parametersEl.isSameNode(elem)) {
                if (elem.classList.contains("uk-open")) {
                    UIkit.accordion(elem.parentNode).toggle(0);
                }
            }
        });
    };
    return GroupCustomComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.GroupCustomComponent = GroupCustomComponent;
