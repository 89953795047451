/**
 * @file The ViewportApi is used for all 3D related functionality.
 * 
 * @module ViewportApi
 * @author Michael Oppitz
 */

let ViewportApi = function (___api, ___refs) {

  const GLOBAL_UTILS = require('../../../shared/util/GlobalUtils'),
        /** @type {module:ThreeDManagerConstantsDefault~ThreeDManagerConstants} */
        THREE_D_MANAGER_CONSTANTS = require('../ThreeDManagerConstants'),
        THREE = require('../../../externals/three'),
        TWEEN = require('@tweenjs/tween.js'),
        MESSAGING_CONSTANTS = require('../../../shared/constants/MessagingConstants'),
        /** @type {module:ViewportApiInterface~ViewportApiInterface} */
        ViewportApiInterface = require('../../interfaces/api/ViewportApiInterface'),
        /** @type {module:CameraApiDefault~CameraApi} */
        CameraApi = require('./CameraApi').CameraApi,
        /** @type {module:LightApiDefault~LightApi} */
        LightApi = require('./LightApi'),
        APIResponse = require('../../../api/v2/ApiResponse'),
        splitAt = index => x => [x.slice(0, index), x.slice(index + 1)];

  let that,
      /** @type {module:ApiInterfaceV2~ApiInterfaceV2} */
      _api = ___api,
      /** @type {module:ThreeDManagerDefault~ThreeDManager} */
      _threeDManager = ___refs.threeDManager,
      /** @type {module:ViewportManager~ViewportManager} */
      _viewportManager = ___refs.viewportManager,
      _getSettingDefinition, _getSettingObject,
      _threeMatrix = new THREE.Matrix4(),
      _tempMatrix = new THREE.Matrix4(),
      _threeMatrices = [];

  /**
   * @extends module:ViewportApiInterface~ViewportApiInterface
   * @lends module:ViewportApi~ViewportApi
   */
  class ViewportApi extends ViewportApiInterface {

    /**
     * @constructs module:ViewportApi~ViewportApi
     */
    constructor() {
      super();

      that = this;

      that.utils = _api.utils;
      that.EVENTTYPE = _api.scene.EVENTTYPE;

      
      _getSettingDefinition = function (settings, defs, keyChain) {
        if (!keyChain)
          keyChain = '';

        if (typeof settings === 'object') {
          for (let key in settings) {
            let s = settings[key];
            if (s.desc) {
              let o = { description: s.desc };
              if (s.type) o.type = s.type;
              let d = GLOBAL_UTILS.deepCopy(o);
              defs[keyChain.split('.value').join('') + key] = d;
            }
            _getSettingDefinition(s, defs, keyChain + key + '.');
          }
        }
      };

      _getSettingObject = function (settings, key) {
        if (key.indexOf('.') !== -1) {
          let currentKey, restKey;
          [currentKey, restKey] = splitAt(key.indexOf('.'))(key);
          let s = settings[currentKey];
          if (!s)
            return;
          if(s.value)
            s = s.value;
          return _getSettingObject(s, restKey);
        } else {
          return settings[key];
        }
      };

      /**
       * The camera api.
       * 
       * @type {module:CameraApiDefault~CameraApi}
       */
      that.camera = new CameraApi(_api, _threeDManager);

      /**
       * The light api.
       * 
       * @type {module:LightApiDefault~LightApi}
       */
      that.lights = new LightApi(_api, {
        threeDManager: _threeDManager,
      });

      that.getViewportRuntimeId = that.getViewportRuntimeId.bind(that);
      that.getContainer = that.getContainer.bind(that);
      that.getScreenshot = that.getScreenshot.bind(that);
      that.getScreenshotAsync = that.getScreenshotAsync.bind(that);

      that.render = that.render.bind(that);
      that.convertTo2D = that.convertTo2D.bind(that);

      that.pause = that.pause.bind(that);
      that.resume = that.resume.bind(that);

      that.addEventListener = that.addEventListener.bind(that);
      that.removeEventListener = that.removeEventListener.bind(that);

      that.getSettingDefinitions = that.getSettingDefinitions.bind(that);
      that.getSettings = that.getSettings.bind(that);
      that.getSetting = that.getSetting.bind(that);
      that.updateSettingAsync = that.updateSettingAsync.bind(that);
      that.updateSettingsAsync = that.updateSettingsAsync.bind(that);

      that.setTransformation = that.setTransformation.bind(that);
      that.getTransformation = that.getTransformation.bind(that);
      that.applyTransformation = that.applyTransformation.bind(that);
      that.resetTransformation = that.resetTransformation.bind(that);
      that.setLiveTransformation = that.setLiveTransformation.bind(that);

      that.updateSelected = that.updateSelected.bind(that);
      that.getSelected = that.getSelected.bind(that);
    }

    /** @inheritdoc */
    getViewportRuntimeId() {
      return _threeDManager.runtimeId;
    }

    /** @inheritdoc */
    getContainer() {
      return _threeDManager.container;
    }

    /** @inheritdoc */
    getScreenshot() {
      return _threeDManager.renderingHandler.getScreenshot();
    }

    /** @inheritdoc */
    getScreenshotAsync() {
      return new Promise(function (resolve) {
        var img=new Image();
        img.onload=function(){
            resolve(APIResponse(null, img.src));
        }
        img.src = _threeDManager.renderingHandler.getScreenshot();
      });
    }

    /** @inheritdoc */
    render() {
      _threeDManager.renderingHandler.render();
      return APIResponse(null, true);
    }

    /** @inheritdoc */
    updateShadowMap() {
      _threeDManager.renderingHandler.updateShadowMap();
      return APIResponse(null, true);
    }

    /** @inheritdoc */
    startContinuousRendering() {
      if (!_threeDManager.renderingHandler.containsContinuousRendering('api_render'))
        _threeDManager.renderingHandler.registerForContinuousRendering('api_render');
      return APIResponse(null, true);
    }

    /** @inheritdoc */
    stopContinuousRendering() {
      _threeDManager.renderingHandler.unregisterForContinuousRendering('api_render');
      return APIResponse(null, true);
    }

    /** @inheritdoc */
    convertTo2D(position) {
      let object = new THREE.Object3D(),
          pos = new THREE.Vector3(),
          canvas = _threeDManager.renderingHandler.getDomElement(),
          canvasPageCoordinates = canvas.getBoundingClientRect(),
          width = canvas.width,
          height = canvas.height;

      object.position.set(position.x, position.y, position.z);
      object.updateMatrixWorld();
      pos.setFromMatrixPosition(object.matrixWorld);
      pos.project(_threeDManager.cameraHandler.camera);

      pos.x = (pos.x * (width / 2)) + (width / 2);
      pos.y = - (pos.y * (height / 2)) + (height / 2);

      // take care of correction by device pixel ratio
      pos.xPixel = pos.x / devicePixelRatio;
      pos.yPixel = pos.y / devicePixelRatio;

      return APIResponse(null, [{
        containerX: pos.xPixel,
        containerY: pos.yPixel,
        clientX: pos.xPixel + canvasPageCoordinates.left,
        clientY: pos.yPixel + canvasPageCoordinates.top,
        pageX: pos.xPixel + canvasPageCoordinates.left + window.pageXOffset,
        pageY: pos.yPixel + canvasPageCoordinates.top + window.pageYOffset,
      }]);
    }




    /** @inheritdoc */
    pause() {
      _threeDManager.pause();
      return APIResponse(null, true);
    }

    /** @inheritdoc */
    resume() {
      _threeDManager.resume();
      return APIResponse(null, true);
    }




    /** @inheritdoc */
    addEventListener(type, cb) {
      // sanity check
      if (!GLOBAL_UTILS.typeCheck(type, 'string') || type.length <= 0)
        return APIResponse('Event type must be a string');
      // check if event type is supported

      if (!Object.keys(that.EVENTTYPE).find((k) => (that.EVENTTYPE[k] === type)))
        return APIResponse('Unsupported event type');

      // compose topic and subscribe to message stream
      let t = MESSAGING_CONSTANTS.messageTopics.SCENE + '.' + type;
      let subtokens = _api.subscribeToMessageStream(t, function (topic, msg) {
        // create event object, add common event properties
        let event = new CustomEvent(type);
        event.api = that;
        if (msg.token) event.token = msg.token;
        // get relevant data parts from message, add special event properties
        let partTypes = {};
        partTypes[MESSAGING_CONSTANTS.messageDataTypes.SCENE_INTERACTION] = null; // null: copy all properties
        partTypes[MESSAGING_CONSTANTS.messageDataTypes.GENERIC] = null; // null: copy all properties
        partTypes[MESSAGING_CONSTANTS.messageDataTypes.SCENE_ANCHORDATA] = null; // null: copy all properties
        partTypes[MESSAGING_CONSTANTS.messageDataTypes.SUBSCENE_UPDATE] = { assets: 1 }; // null: copy all properties
        for (let pt in partTypes) {
          let part = msg.getUniquePartByType(pt);
          if (part) {
            if (part.data) part = part.data;
            // add special event properties
            let props = partTypes[pt] ? partTypes[pt] : part;
            for (let k in props) {
              // in case event[k] is an array, and part[k] is an array, should we merge the arrays?
              event[k] = part[k];
            }
          }
        }

        // filter out other viewports
        if(event.viewportRuntimeId && event.viewportRuntimeId !== event.api.getViewportRuntimeId())
          return;

        // invoke callback (exception handling takes place in _api.subscribeToMessageStream)
        cb(event);
      });
      return APIResponse(null, subtokens);
    }

    /** @inheritdoc */
    removeEventListener(token) {
      return APIResponse(null, _api.unsubscribeFromMessageStream(token));
    }




    /** @inheritdoc */
    getSettingDefinitions() {
      let defs = {};
      _getSettingDefinition(THREE_D_MANAGER_CONSTANTS.settingsDefinition, defs, '');
      return defs;    
    }

    /** @inheritdoc */
    getSettings(keys) { 
      if (!Array.isArray(keys))
        keys = Object.keys(that.getSettingDefinitions());
      let settings = {};
      keys.forEach((k) => {
        if (_threeDManager.hasSetting(k)) {
          let v = _threeDManager.getSetting(k);
          GLOBAL_UTILS.forceAtPath(settings, k, v);
        }
      });
      return settings;
    }

    /** @inheritdoc */
    getSetting(k) {
      if (!_threeDManager.hasSetting(k)) return;
      return _threeDManager.getSetting(k);
    }

    /** @inheritdoc */
    updateSettingAsync(k, val) { 
      let m = _getSettingObject(THREE_D_MANAGER_CONSTANTS.settingsDefinition, k);
      if (m === undefined) return Promise.resolve(APIResponse('Setting does not exist', false));

      // type checking
      if (m.type !== undefined && m.type !== null) {
        if (GLOBAL_UTILS.typeCheck(m.type, 'string')) {
          if (GLOBAL_UTILS.typeCheck(val, 'm.type')) {
            return Promise.resolve(APIResponse('Setting has wrong value type', false));
          }
        }
        else if (typeof m.type === 'function') {
          if (!m.type(val)) {
            return Promise.resolve(APIResponse('Setting has wrong value type', false));
          }
        }
      }
      // transformation
      if (m.hasOwnProperty('transform') && typeof m.transform === 'function') {
        val = m.transform(val);
      }
      // update setting
      return _threeDManager.updateSettingAsync(k, val).then(
        (r) => {
          if (!r) {
            return Promise.resolve(APIResponse('Update of setting failed', false));
          }
          else {
            return Promise.resolve(APIResponse(null, true));
          }
        },
        () => {
          return Promise.resolve(APIResponse('Update of setting failed', false));
        }
      );
    }

    /** @inheritdoc */
    updateSettingsAsync(settings) {
      // get paths of settings object
      let paths = [];
      GLOBAL_UTILS.getPaths(settings, paths);

      // create an empty object which will hold the results
      let results = {};

      // create an empty promise to attach further ones to
      let promiseChain = Promise.resolve();
      for (let path of paths) {
        // attach promise for updating the setting at path
        promiseChain = promiseChain.then(function () {
          return that.updateSettingAsync(path, GLOBAL_UTILS.getAtPath(settings, path));
        });
        // attach promise for storing the result of the setting update
        promiseChain = promiseChain.then(
          function (r) {
            if (r.err) {
              GLOBAL_UTILS.forceAtPath(results, path, false);
            } else {
              GLOBAL_UTILS.forceAtPath(results, path, true);
            }
          },
          function () {
            GLOBAL_UTILS.forceAtPath(results, path, false);
          }
        );
      }

      // add final result to promise chain
      return promiseChain.then(
        function () {
          return APIResponse(null, results);
        }
      );
    }



    /** @inheritdoc */
    setTransformation(matrix) {
      if (!_threeDManager.transformationMatrices)
        _threeDManager.transformationMatrices = [];

      if ( typeof matrix[0] === 'number' || matrix.isMatrix4 )
        matrix = [matrix];

      _threeMatrix.identity();

      for (let i = 0, len = matrix.length > _threeDManager.transformationMatrices.length ? matrix.length : _threeDManager.transformationMatrices.length; i < len; i++) {
        if (matrix[i]) {
          _threeDManager.transformationMatrices[i] = matrix[i].isMatrix4 ? matrix[i] : _tempMatrix.clone().set(...matrix[i]);
        } else if (!_threeDManager.transformationMatrices[i]) {
          _threeDManager.transformationMatrices[i] = _tempMatrix.clone();
        }
        _threeMatrix.premultiply(_threeDManager.transformationMatrices[i]);
      }

      _threeDManager.transformationMatrix.identity();
      _threeDManager.transformationMatrix.multiply(_threeMatrix);
      _threeDManager.renderingHandler.updateShadowMap();
      _threeDManager.renderingHandler.render();
      return APIResponse(null, true);
    }

    /** @inheritdoc */
    getTransformation() {
      if (_threeDManager.transformationMatrices && _threeDManager.transformationMatrices.length > 0) {
        let tmp = [];
        for (let i = 0, len = _threeDManager.transformationMatrices.length; i < len; i++)
          tmp[i] = _threeDManager.transformationMatrices[i].clone();
        return tmp;
      } else {
        return [_threeDManager.transformationMatrix.clone()];
      }
    }

    /** @inheritdoc */
    applyTransformation(matrix) {
      if (!_threeDManager.transformationMatrices)
        _threeDManager.transformationMatrices = [];

      if ( typeof matrix[0] === 'number' || matrix.isMatrix4 )
        matrix = [matrix];

      _threeMatrix.identity();

      for (let i = 0, len = matrix.length > _threeDManager.transformationMatrices.length ? matrix.length : _threeDManager.transformationMatrices.length; i < len; i++) {
        if (matrix[i] != null)
          _threeMatrices[i] = matrix[i].isMatrix4 ? matrix[i] : _tempMatrix.clone().set(...matrix[i]);

        if (_threeDManager.transformationMatrices[i]) {
          if (matrix[i])
            _threeDManager.transformationMatrices[i].multiply(_threeMatrices[i]);
        } else {
          if (matrix[i]) {
            _threeDManager.transformationMatrices[i] = _threeMatrices[i];
          } else {
            _threeDManager.transformationMatrices[i] = _tempMatrix.clone();
          }
        }
        _threeMatrix.premultiply(_threeDManager.transformationMatrices[i]);

      }

      _threeDManager.transformationMatrix.identity();
      _threeDManager.transformationMatrix.premultiply(_threeMatrix);
      _threeDManager.renderingHandler.updateShadowMap();
      _threeDManager.renderingHandler.render();
      return APIResponse(null, true);
    }

    /** @inheritdoc */
    resetTransformation() {
      _threeDManager.transformationMatrix = _tempMatrix.clone();
      _threeDManager.transformationMatrices = [];
      _threeDManager.renderingHandler.updateShadowMap();
      _threeDManager.renderingHandler.render();
      return APIResponse(null, true);
    }

    setLiveTransformation(liveTransformations, duration) {
      let group = new TWEEN.Group(),
          response = [];
      for (let i = 0, len = liveTransformations.length; i < len; i++)
        response.push(_threeDManager.setLiveTransformation(group, liveTransformations[i].scenePaths, liveTransformations[i].transformations, liveTransformations[i].reset, duration));

      for (let i = 0, len = response.length; i < len; i++)
        response[i].start();

      return response;
    }




    /** @inheritdoc */
    updateSelected(select, deselect) {
      return _threeDManager.interactionHandler.setSelectedPaths(select, deselect);
    }

    /** @inheritdoc */
    getSelected() {
      return _threeDManager.interactionHandler.getSelectedPaths();
    }

    /** @inheritdoc */
    startExternalDragEvent(path, eventType) {
      return _threeDManager.interactionHandler.startExternalDragEvent(path, eventType);
    }



    /** @inheritdoc */
    toggleGeometry(show, hide) {
      if (GLOBAL_UTILS.typeCheck(show, 'string'))
        show = [show];
      if (GLOBAL_UTILS.typeCheck(hide, 'string'))
        hide = [hide];
      if (!Array.isArray(show) || !Array.isArray(hide))
        return APIResponse('The provided input is not valid');

      if (!show.every(function (currentValue) { return GLOBAL_UTILS.typeCheck(currentValue, 'string'); }) ||
        !hide.every(function (currentValue) { return GLOBAL_UTILS.typeCheck(currentValue, 'string'); }))
        return APIResponse('The provided input is not valid');

      _threeDManager.toggleGeometry(show, hide);
      _threeDManager.renderingHandler.render();
      return APIResponse(null, true);
    }
  }

  return new ViewportApi();
};

module.exports = ViewportApi;