"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseCameraHandler_1 = require("./BaseCameraHandler");
var THREE = require("../../../../externals/three");
var HooksAndNotifiers_1 = require("../../helpers/HooksAndNotifiers");
var PerspectiveCameraHooksAndNotifiers_1 = require("./hooks/PerspectiveCameraHooksAndNotifiers");
var CameraControls_1 = require("./controls/CameraControls");
var OrbitControlsManager_1 = require("./controls/orbit/OrbitControlsManager");
var PerspectiveCameraHandler = /** @class */ (function (_super) {
    __extends(PerspectiveCameraHandler, _super);
    // #endregion Properties (2)
    // #region Constructors (1)
    function PerspectiveCameraHandler(settings, handlers) {
        var _this = _super.call(this, settings, handlers) || this;
        // #region Properties (2)
        _this._defaultSettings = {
            near: 0.01,
            far: 100
        };
        _this._perspectiveSettings = _this._settings.getSection('cameraTypes.perspective');
        _this._camera = new THREE.PerspectiveCamera(_this._perspectiveSettings.getSetting('fov'), _this._container.offsetWidth / _this._container.offsetHeight, _this._defaultSettings.near, _this._defaultSettings.far);
        _this._camera.up.set(0, 0, 1);
        _this._handlers.threeDManager.helpers.addSceneObject(_this._camera);
        var defaults = _this._perspectiveSettings.getSetting('default');
        if (defaults.position.x === 0 && defaults.position.y === 0 && defaults.position.z === 0 &&
            defaults.target.x === 0 && defaults.target.y === 0 && defaults.target.z === 0)
            defaults = _this.getZoomExtentsPosition();
        _this._cameraControls = new CameraControls_1.CameraControls(_this._camera, defaults, _this._handlers.renderingHandler.getDomElement(), function () {
            _this._handlers.renderingHandler.registerForContinuousRendering(_this.CAMERA_MOVING_ID);
        }, _this.cameraMovementEventDispatch.bind(_this));
        _this._cameraControlsManager = new OrbitControlsManager_1.OrbitControlsManager(_this._settings.getSection('controls.orbit'), _this._cameraControls, _this._handlers.renderingHandler.render, _this._handlers.threeDManager.warn);
        HooksAndNotifiers_1.registerHooksAndNotifiers(_this, _this._perspectiveSettings, PerspectiveCameraHooksAndNotifiers_1.PerspectiveCameraHooks, PerspectiveCameraHooksAndNotifiers_1.PerspectiveCameraNotifiers);
        return _this;
    }
    // #endregion Constructors (1)
    // #region Public Methods (9)
    PerspectiveCameraHandler.prototype.activate = function () {
        this.resume();
        if (this._handlers.threeDManager.getSetting("material.environmentMapAsBackground"))
            this._handlers.materialHandler.setSceneBackground(this._handlers.materialHandler.getEnvironmentMap());
    };
    PerspectiveCameraHandler.prototype.adjustToBoundingSphere = function (bs) {
        this._sceneBS = bs;
        this._camera.far = this._camera.fov < 10 ? this._camera.fov * 100.0 * this._defaultSettings.far * bs.radius : this._defaultSettings.far * bs.radius;
        this._camera.near = this._camera.fov < 10 ? this._camera.fov * 100.0 * this._defaultSettings.near * bs.radius : this._defaultSettings.near * bs.radius;
        this._camera.updateProjectionMatrix();
    };
    PerspectiveCameraHandler.prototype.deactivate = function () {
        this.pause();
    };
    PerspectiveCameraHandler.prototype.getZoomExtentsPosition = function (bb) {
        this._camera.updateProjectionMatrix();
        var box = new THREE.Box3();
        box = bb || box.setFromObject(this._geometryNode);
        var center = new THREE.Vector3();
        box.getCenter(center);
        var zoomExtentsHelperDir = new THREE.Vector3();
        zoomExtentsHelperDir.subVectors(box.max, center);
        zoomExtentsHelperDir.multiplyScalar(this._settings.getSetting('zoomExtentsFactor'));
        box = new THREE.Box3(new THREE.Vector3().subVectors(center, zoomExtentsHelperDir), new THREE.Vector3().addVectors(center, zoomExtentsHelperDir));
        var cameraDirection = new THREE.Vector3();
        this._camera.getWorldDirection(cameraDirection);
        var cross = new THREE.Vector3().crossVectors(this._camera.up, cameraDirection).normalize();
        var up = new THREE.Vector3().crossVectors(cross, cameraDirection).normalize();
        var position = center.clone().add(cameraDirection.clone().multiplyScalar(-1));
        var points = [];
        points.push(new THREE.Vector3(box.min.x, box.min.y, box.min.z));
        points.push(new THREE.Vector3(box.min.x, box.min.y, box.max.z));
        points.push(new THREE.Vector3(box.min.x, box.max.y, box.min.z));
        points.push(new THREE.Vector3(box.min.x, box.max.y, box.max.z));
        points.push(new THREE.Vector3(box.max.x, box.min.y, box.min.z));
        points.push(new THREE.Vector3(box.max.x, box.min.y, box.max.z));
        points.push(new THREE.Vector3(box.max.x, box.max.y, box.min.z));
        points.push(new THREE.Vector3(box.max.x, box.max.y, box.max.z));
        var fovDown = cameraDirection.clone().applyAxisAngle(cross, (this._camera.getEffectiveFOV() / 2) * (Math.PI / 180));
        var fovUp = cameraDirection.clone().applyAxisAngle(cross, -(this._camera.getEffectiveFOV() / 2) * (Math.PI / 180));
        var hFoV = 2 * Math.atan(Math.tan(this._camera.getEffectiveFOV() * Math.PI / 180 / 2) * this._camera.aspect);
        var fovRight = cameraDirection.clone().applyAxisAngle(up, hFoV / 2);
        var fovLeft = cameraDirection.clone().applyAxisAngle(up, -hFoV / 2);
        var planeCross = new THREE.Plane(cross.clone(), 0);
        planeCross.setFromNormalAndCoplanarPoint(cross, center);
        var planeUp = new THREE.Plane(this._camera.up.clone(), 0);
        planeUp.setFromNormalAndCoplanarPoint(up.clone(), center);
        var distanceCamera = 0.0;
        for (var i = 0; i < points.length; i++) {
            {
                var projected = new THREE.Vector3();
                planeCross.projectPoint(points[i], projected);
                var toP = projected.clone().sub(position).normalize();
                if (cameraDirection.dot(fovDown) > cameraDirection.dot(toP)) {
                    var cameraPoint = new THREE.Vector3();
                    if (fovDown.dot(toP) > fovUp.dot(toP)) {
                        var pointRay = new THREE.Ray(projected, fovDown.clone().multiplyScalar(-1));
                        pointRay.intersectPlane(planeUp, cameraPoint);
                        distanceCamera = Math.max(distanceCamera, center.distanceTo(cameraPoint));
                    }
                    else {
                        var pointRay = new THREE.Ray(projected, fovUp.clone().multiplyScalar(-1));
                        pointRay.intersectPlane(planeUp, cameraPoint);
                        distanceCamera = Math.max(distanceCamera, center.distanceTo(cameraPoint));
                    }
                }
            }
            {
                var projected = new THREE.Vector3();
                planeUp.projectPoint(points[i], projected);
                var toP = projected.clone().sub(position).normalize();
                if (cameraDirection.dot(fovRight) > cameraDirection.dot(toP)) {
                    var cameraPoint = new THREE.Vector3();
                    if (fovRight.dot(toP) > fovLeft.dot(toP)) {
                        var pointRay = new THREE.Ray(projected, fovRight.clone().multiplyScalar(-1));
                        pointRay.intersectPlane(planeCross, cameraPoint);
                        distanceCamera = Math.max(distanceCamera, center.distanceTo(cameraPoint));
                    }
                    else {
                        var pointRay = new THREE.Ray(projected, fovLeft.clone().multiplyScalar(-1));
                        pointRay.intersectPlane(planeCross, cameraPoint);
                        distanceCamera = Math.max(distanceCamera, center.distanceTo(cameraPoint));
                    }
                }
            }
        }
        position = center.clone().add(cameraDirection.clone().multiplyScalar(-distanceCamera));
        return {
            position: position.clone(),
            target: center.clone()
        };
    };
    PerspectiveCameraHandler.prototype.onResize = function (width, height) {
        this._camera.aspect = width / height;
        this._camera.setViewOffset(width, height, 0, 0, width, height);
        this._camera.updateProjectionMatrix();
    };
    PerspectiveCameraHandler.prototype.resetPositionAndTarget = function (options) {
        var defaults = this._perspectiveSettings.getSetting('default');
        if (defaults.position.x === 0 && defaults.position.y === 0 && defaults.position.z === 0 &&
            defaults.target.x === 0 && defaults.target.y === 0 && defaults.target.z === 0)
            defaults = this.getZoomExtentsPosition();
        return this.setPositionAndTarget(defaults.position, defaults.target, options);
    };
    PerspectiveCameraHandler.prototype.setCameraPath = function (path, options) {
        var scope = 'PerspectiveCameraHandler.setCameraPath';
        if (!path)
            return Promise.resolve(false);
        if (!Array.isArray(path))
            return Promise.resolve(false);
        if (path.length === 0)
            return Promise.resolve(false);
        // check input sanity
        var adjustedPath = [];
        for (var _i = 0, path_1 = path; _i < path_1.length; _i++) {
            var point = path_1[_i];
            if (!this._globalUtils.typeCheck(point.position, 'vector3any', this._handlers.threeDManager.warn, scope))
                return Promise.resolve(false);
            if (!this._globalUtils.typeCheck(point.target, 'vector3any', this._handlers.threeDManager.warn, scope))
                return Promise.resolve(false);
            adjustedPath.push({
                position: this._globalUtils.toVector3(point.position),
                target: this._globalUtils.toVector3(point.target)
            });
        }
        if (!this._cameraControls.isWithinRestrictions(adjustedPath[adjustedPath.length - 1].position, adjustedPath[adjustedPath.length - 1].target))
            return Promise.resolve(false);
        if (!options)
            options = {};
        options.duration = options.duration >= 0 ? options.duration : this._settings.getSetting('cameraMovementDuration');
        if (options.default) {
            this._perspectiveSettings.updateSetting('default.position', adjustedPath[adjustedPath.length - 1].position);
            this._perspectiveSettings.updateSetting('default.target', adjustedPath[adjustedPath.length - 1].target);
        }
        if (this._firstTimeVisible.resolved === false)
            options.duration = 0;
        return this._cameraControls.animate(adjustedPath, options);
    };
    PerspectiveCameraHandler.prototype.setPositionAndTarget = function (position, target, options) {
        var scope = 'PerspectiveCameraHandler.setPositionAndTarget';
        if (!(this._globalUtils.typeCheck(position, 'vector3any', this._handlers.threeDManager.warn, scope) &&
            this._globalUtils.typeCheck(target, 'vector3any', this._handlers.threeDManager.warn, scope)))
            return Promise.resolve(false);
        // convert input to THREE.Vector3
        var position_to = this._globalUtils.toVector3(position);
        var target_to = this._globalUtils.toVector3(target);
        if (!this._cameraControls.isWithinRestrictions(position_to, target_to))
            return Promise.resolve(false);
        if (!options)
            options = {};
        options.duration = options.duration >= 0 ? options.duration : this._settings.getSetting('cameraMovementDuration');
        if (options.default) {
            this._perspectiveSettings.updateSetting('default.position', position_to);
            this._perspectiveSettings.updateSetting('default.target', target_to);
        }
        if (this._firstTimeVisible.resolved === false)
            options.duration = 0;
        return this._cameraControls.animate([
            { position: this._cameraControls.position.clone(), target: this._cameraControls.target.clone() },
            { position: position_to, target: target_to }
        ], options);
    };
    PerspectiveCameraHandler.prototype.zoomExtents = function (options, scenePathsOrBB) {
        var bb = scenePathsOrBB ? scenePathsOrBB instanceof THREE.Box3 ? scenePathsOrBB : this._globalUtils.typeCheck(scenePathsOrBB, 'box3any') ? new THREE.Box3(this._globalUtils.toVector3(scenePathsOrBB.min), this._globalUtils.toVector3(scenePathsOrBB.max)) : this._handlers.threeDManager.computeBoundingBox(scenePathsOrBB) : this._handlers.threeDManager.computeBoundingBox();
        var _a = this.getZoomExtentsPosition(bb), position = _a.position, target = _a.target;
        return this.setPositionAndTarget(position, target, options);
    };
    return PerspectiveCameraHandler;
}(BaseCameraHandler_1.BaseCameraHandler));
exports.PerspectiveCameraHandler = PerspectiveCameraHandler;
