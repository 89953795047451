
let threeDManagerConstants;
{
  const GLOBAL_UTILS = require('../../shared/util/GlobalUtils'),
    LOGGING_CONSTANTS = require('../../shared/constants/LoggingConstants'),
    LOGGING_LEVELS = LOGGING_CONSTANTS.loggingLevels;

  let cameraViewTypes = {
    MIN: 0,
    PERSPECTIVE: 0,
    TOP: 1,
    BOTTOM: 2,
    RIGHT: 3,
    LEFT: 4,
    BACK: 5,
    FRONT: 6,
    MAX: 6
  };

  let cameraControlsTypes = {
    MIN: 0,
    ORBIT: 0,
    FPS: 1,
    ORTHOGRAPHIC: 2,
    MAX: 2
  };

  let settingsDefinition = {
    show: {
      value: false,
      setting: 'show',
      desc: 'Show / hide the scene',
      type: 'boolean'
    },
    showTransition: {
      value: '1s'
    },
    duration: {
      value: 0,
      setting: 'duration',
      desc: 'Set fade in / fade out duration',
      type: 'number'
    },
    fullscreen: {
      value: false,
      setting: 'fullscreen',
      desc: 'Enable / disable fullscreen mode',
      type: 'boolean'
    },
    gridVisibility: {
      value: true,
      setting: 'gridVisibility',
      desc: 'Show / hide the grid',
      type: 'boolean'
    },
    groundPlaneVisibility: {
      value: true,
      setting: 'groundPlaneVisibility',
      desc: 'Show / hide the ground plane',
      type: 'boolean'
    },
    groundPlaneReflectionVisibility: {
      value: false,
      setting: 'groundPlaneReflectionVisibility',
      desc: 'Enable / disable the reflectivity of the groundplane ',
      type: 'boolean'
    },
    groundPlaneReflectionThreshold: {
      value: 0.01,
      setting: 'groundPlaneReflectionThreshold',
      desc: 'Allows to control the distance to objects that are still reflected by the groundplane ',
      type: (v) => (typeof v === 'number' && v >= 0)
    },
    render: {
      ambientOcclusion: {
        value: true,
        setting: 'render.ambientOcclusion',
        desc: 'Enable / disable ambient occlusion for rendering',
        type: 'boolean'
      },
      beautyRenderDelay: {
        value: 50,
        setting: 'beautyRenderDelay',
        desc: 'Amount of which the beauty rendering is delayed',
        type: 'number'
      },
      clearColor: {
        value: '#ffffff',
        setting: 'render.clearColor',
        desc: 'Set background color',
        type: (v) => (GLOBAL_UTILS.typeCheck(v, 'color') ? true : false)
      },
      clearAlpha: {
        value: 1.0,
        setting: 'render.clearAlpha',
        desc: 'Set background alpha value',
        type: (v) => (typeof v === 'number' && v >= 0 && v <= 1 ? true : false)
      },
      devicePixelRatio: {
        value: 1.0
      },
      pointSize: {
        value: 1.0,
        setting: 'render.pointSize',
        desc: 'Set size of point objects',
        type: (v) => (typeof v === 'number' && v >= 0 ? true : false)
      },
      sao: {
        samples: {
          value: 8
        },
        intensity: {
          value: 0.1,
        },
        kernelRadius: {
          value: 8,
        },
        standardDev: {
          value: 25
        },
      },
      shadows: {
        value: true,
        setting: 'render.shadows',
        desc: 'Enable / disable shadows for rendering',
        type: 'boolean'
      },
    },
    camera: {
      cameraTypes: {
        perspective: {
          default: {
            value: {
              position: {
                value: {
                  x: 0, y: 0, z: 0
                },
                setting: 'camera.cameraTypes.perspective.default.position',
                desc: 'Default position for the perspective camera',
                type: 'vector3any',
                transform: (v) => (GLOBAL_UTILS.toVector3(v))
              },
              target: {
                value: {
                  x: 0, y: 0, z: 0
                },
                setting: 'camera.cameraTypes.perspective.default.target',
                desc: 'Default target for the perspective camera',
                type: 'vector3any',
                transform: (v) => (GLOBAL_UTILS.toVector3(v))
              }
            },
            setting: 'camera.cameraTypes.perspective.default',
            desc: 'Default position and target for the perspective camera',
            type: (v) => (v && GLOBAL_UTILS.typeCheck(v.position, 'vector3obj') && GLOBAL_UTILS.typeCheck(v.target, 'vector3obj'))
          },
          fov: {
            value: 45,
            setting: 'camera.cameraTypes.perspective.fov',
            desc: 'Camera frustum vertical field of view angle, unit degree, interval [0,180]',
            type: (v) => (typeof v === 'number' && v > 0 && v < 180 ? true : false)
          },
          controls: {
            value: cameraControlsTypes.ORBIT,
            setting: 'camera.cameraTypes.perspective.controls',
            desc: 'Set camera control type',
            type: (v) => (typeof v === 'number' && (v === cameraControlsTypes.ORBIT || v === cameraControlsTypes.FPS) ? true : false)
          }
        },
        orthographic: {
          default: {
            value: {
              position: {
                value: {
                  x: 0, y: 0, z: 0
                },
                setting: 'camera.cameraTypes.orthographic.default.position',
                desc: 'Default position for the orthographic camera',
                type: 'vector3any',
                transform: (v) => (GLOBAL_UTILS.toVector3(v))
              },
              target: {
                value: {
                  x: 0, y: 0, z: 0
                },
                setting: 'camera.cameraTypes.orthographic.default.target',
                desc: 'Default target for the orthographic camera',
                type: 'vector3any',
                transform: (v) => (GLOBAL_UTILS.toVector3(v))
              }
            },
            setting: 'camera.cameraTypes.orthographic.default',
            desc: 'Default position and target for the orthographic camera',
            type: (v) => (v && GLOBAL_UTILS.typeCheck(v.position, 'vector3obj') && GLOBAL_UTILS.typeCheck(v.target, 'vector3obj'))

          }
        },
        active: {
          value: cameraViewTypes.PERSPECTIVE,
          setting: 'camera.cameraTypes.active',
          desc: 'Set camera type',
          type: (v) => (typeof v === 'number' && v >= cameraViewTypes.MIN && v <= cameraViewTypes.MAX ? true : false)
        }
      },
      controls: {
        orbit: {
          autoRotationSpeed: {
            value: 0.0,
            setting: 'camera.controls.orbit.autoRotationSpeed',
            desc: 'Speed of autoration, can be negative, also refer to enableAutoRotation',
            type: 'number'
          },
          damping: {
            value: 0.1,
            setting: 'camera.controls.orbit.damping',
            desc: 'How much to damp camera movements by the user',
            type: 'notnegative'
          },
          enableAutoRotation: {
            value: false,
            setting: 'camera.controls.orbit.enableAutoRotation',
            desc: 'Enable / disable automatic rotation of the camera, also refer to autoRotationSpeed',
            type: 'boolean'
          },
          enableKeyPan: {
            value: false,
            setting: 'camera.controls.orbit.enableKeyPan',
            desc: 'Enable / disable panning using the keyboard, also refer to enablePan',
            type: 'boolean'
          },
          enablePan: {
            value: true,
            setting: 'camera.controls.orbit.enablePan',
            desc: 'Enable / disable panning in general, also refer to enableKeyPan',
            type: 'boolean'
          },
          enableRotation: {
            value: true,
            setting: 'camera.controls.orbit.enableRotation',
            desc: 'Enable / disable camera rotation',
            type: 'boolean'
          },
          enableZoom: {
            value: true,
            setting: 'camera.controls.orbit.enableZoom',
            desc: 'Enable / disable zooming',
            type: 'boolean'
          },
          input: {
            value: {
              keys: {
                up: 38,
                down: 40,
                left: 37,
                right: 39
              },
              mouse: {
                rotate: 0,
                zoom: 1,
                pan: 2
              },
              touch: {
                rotate: 1,
                zoom: 2,
                pan: 3
              },
            },
            setting: 'camera.controls.orthographic.input',
            type: (v) => true

          },
          keyPanSpeed: {
            value: 0.5,
            setting: 'camera.controls.orbit.keyPanSpeed',
            desc: 'Speed of panning when using the keyboard',
            type: 'number'
          },
          movementSmoothness: {
            value: 0.5,
            setting: 'camera.controls.orbit.movementSmoothness',
            desc: 'How much to the current movement is affected by the previous one',
            type: 'notnegative'
          },
          restrictions: {
            position: {
              cube: {
                value: {
                  min: {
                    value: {
                      x: -Infinity, y: -Infinity, z: -Infinity
                    },
                    setting: 'camera.controls.orbit.restrictions.position.cube.min',
                    desc: 'Restriction of the camera position inside a cube, minimum corner of the cube',
                    type: 'vector3any'
                  },
                  max: {
                    value: {
                      x: Infinity, y: Infinity, z: Infinity
                    },
                    setting: 'camera.controls.orbit.restrictions.position.cube.max',
                    desc: 'Restriction of the camera position inside a cube, maximum corner of the cube',
                    type: 'vector3any'
                  }
                },
                setting: 'camera.controls.orbit.restrictions.position.cube',
                desc: 'Restriction of the camera position inside a cube, minimum and maximum corner of the cube'
              },
              sphere: {
                value: {
                  center: {
                    value: {
                      x: 0, y: 0, z: 0
                    },
                    setting: 'camera.controls.orbit.restrictions.position.sphere.center',
                    desc: 'Restriction of the camera position inside a sphere, center of the sphere',
                    type: 'vector3any'
                  },
                  radius: {
                    value: Infinity,
                    setting: 'camera.controls.orbit.restrictions.position.sphere.radius',
                    desc: 'Restriction of the camera position inside a sphere, radius of the sphere',
                    type: 'notnegative'
                  }
                },
                setting: 'camera.controls.orbit.restrictions.position.sphere',
                desc: 'Restriction of the camera position inside a sphere, center and radius of the sphere'
              }
            },
            target: {
              cube: {
                value: {
                  min: {
                    value: {
                      x: -Infinity, y: -Infinity, z: -Infinity
                    },
                    setting: 'camera.controls.orbit.restrictions.target.cube.min',
                    desc: 'Restriction of the camera target inside a cube, minimum corner of the cube',
                    type: 'vector3any'
                  },
                  max: {
                    value: {
                      x: Infinity, y: Infinity, z: Infinity
                    },
                    setting: 'camera.controls.orbit.restrictions.target.cube.max',
                    desc: 'Restriction of the camera target inside a cube, maximum corner of the cube',
                    type: 'vector3any'
                  }
                },
                setting: 'camera.controls.orbit.restrictions.target.cube',
                desc: 'Restriction of the camera target inside a cube, minimum and maximum corner of the cube'
              },
              sphere: {
                value: {
                  center: {
                    value: {
                      x: 0, y: 0, z: 0
                    },
                    setting: 'camera.controls.orbit.restrictions.target.sphere.center',
                    desc: 'Restriction of the camera target inside a sphere, center of the sphere',
                    type: 'vector3any'
                  },
                  radius: {
                    value: Infinity,
                    setting: 'camera.controls.orbit.restrictions.target.sphere.radius',
                    desc: 'Restriction of the camera target inside a sphere, radius of the sphere',
                    type: 'notnegative'
                  }
                },
                setting: 'camera.controls.orbit.restrictions.target.sphere',
                desc: 'Restriction of the camera target inside a sphere, center and radius of the sphere'
              }
            },
            rotation: {
              value: {
                minPolarAngle: {
                  value: 0,
                  setting: 'camera.controls.orbit.restrictions.rotation.minPolarAngle',
                  desc: 'Minimum polar angle of the camera position with respect to the camera target, unit degree, interval [0,180]',
                  type: (v) => (typeof v === 'number' && v >= 0 && v <= 180 ? true : false),
                },
                maxPolarAngle: {
                  value: 180,
                  setting: 'camera.controls.orbit.restrictions.rotation.maxPolarAngle',
                  desc: 'Maximum polar angle of the camera position with respect to the camera target, unit degree, interval [0,180]',
                  type: (v) => (typeof v === 'number' && v >= 0 && v <= 180 ? true : false),
                },
                minAzimuthAngle: {
                  value: -Infinity,
                  setting: 'camera.controls.orbit.restrictions.rotation.minAzimuthAngle',
                  desc: 'Minimum azimuth angle of the camera position with respect to the camera target, unit degree, interval [-180,180]',
                  type: 'number'
                },
                maxAzimuthAngle: {
                  value: Infinity,
                  setting: 'camera.controls.orbit.restrictions.rotation.maxAzimuthAngle',
                  desc: 'Maximum azimuth angle of the camera position with respect to the camera target, unit degree, interval [-180,180]',
                  type: 'number'
                }
              },
              setting: 'camera.controls.orbit.restrictions.rotation',
              desc: 'Minimum and maximum polar and azimuth angle of the camera position with respect to the camera target, unit degree',
              type: (v) => (v && Object.keys(v).every((k) => (typeof v[k] === 'number')))
            },
            zoom: {
              value: {
                minDistance: {
                  value: 0,
                  setting: 'camera.controls.orbit.restrictions.zoom.minDistance',
                  desc: 'Minimum distance between camera position and target',
                  type: 'notnegative'
                },
                maxDistance: {
                  value: Infinity,
                  setting: 'camera.controls.orbit.restrictions.zoom.maxDistance',
                  desc: 'Maximum distance between camera position and target',
                  type: 'notnegative'
                }
              },
              setting: 'camera.controls.orbit.restrictions.zoom',
              desc: 'Minimum and maximum distance between camera position and target',
              type: (v) => (v && GLOBAL_UTILS.typeCheck(v.minDistance, 'notnegative') && GLOBAL_UTILS.typeCheck(v.maxDistance, 'notnegative'))
            }

          },
          rotationSpeed: {
            value: 0.5,
            setting: 'camera.controls.orbit.rotationSpeed',
            desc: 'Speed of camera rotation',
            type: 'number'
          },
          panSpeed: {
            value: 0.5,
            setting: 'camera.controls.orbit.panSpeed',
            desc: 'Speed of panning',
            type: 'number'
          },
          zoomSpeed: {
            value: 0.5,
            setting: 'camera.controls.orbit.zoomSpeed',
            desc: 'Speed of zooming',
            type: 'notnegative'
          },
        },
        fps: {

        },
        orthographic: {
          damping: {
            value: 0.1,
            setting: 'camera.controls.orthographic.damping',
            desc: 'How much to damp camera movements by the user',
            type: 'notnegative'
          },
          enableKeyPan: {
            value: false,
            setting: 'camera.controls.orbit.enableKeyPan',
            desc: 'Enable / disable panning using the keyboard, also refer to enablePan',
            type: 'boolean'
          },
          enablePan: {
            value: true,
            setting: 'camera.controls.orthographic.enablePan',
            desc: 'Enable / disable panning in general, also refer to enableKeyPan',
            type: 'boolean'
          },
          enableZoom: {
            value: true,
            setting: 'camera.controls.orthographic.enableZoom',
            desc: 'Enable / disable zooming',
            type: 'boolean'
          },
          input: {
            value: {
              keys: {
                up: 38,
                down: 40,
                left: 37,
                right: 39
              },
              mouse: {
                rotate: 0,
                zoom: 1,
                pan: 2
              },
              touch: {
                rotate: -1,
                zoom: 2,
                pan: 1
              },
            },
            setting: 'camera.controls.orthographic.input',
            type: (v) => true
          },
          keyPanSpeed: {
            value: 0.5,
            setting: 'camera.controls.orthographic.keyPanSpeed',
            desc: 'Speed of panning when using the keyboard',
            type: 'number'
          },
          movementSmoothness: {
            value: 0.5,
            setting: 'camera.controls.orthographic.movementSmoothness',
            desc: 'How much to the current movement is affected by the previous one',
            type: 'notnegative'
          },
          panSpeed: {
            value: 0.5,
            setting: 'camera.controls.orthographic.panSpeed',
            desc: 'Speed of panning',
            type: 'number'
          },
          zoomSpeed: {
            value: 0.5,
            setting: 'camera.controls.orthographic.zoomSpeed',
            desc: 'Speed of zooming',
            type: 'notnegative'
          },
        }
      },
      autoAdjust: {
        value: false,
        setting: 'camera.autoAdjust',
        desc: 'Enable / disable that the camera adjusts to geometry updates',
        type: 'boolean'
      },
      cameraMovementDuration: {
        value: 0,
        setting: 'camera.cameraMovementDuration',
        desc: 'Default duration of camera movements',
        type: 'notnegative'
      },
      enableCameraControls: {
        value: true,
        setting: 'camera.enableCameraControls',
        desc: 'Enable / disable camera controls',
        type: 'boolean'
      },
      revertAtMouseUp: {
        value: false,
        setting: 'camera.revertAtMouseUp ',
        desc: 'Enable / disable if the mouse should reset on mouse up',
        type: 'boolean'
      },
      revertAtMouseUpDuration: {
        value: 800,
        setting: 'camera.revertAtMouseUpDuration',
        desc: 'The duration of the transition of the revertAtMouseUp',
        type: 'notnegative'
      },
      zoomExtentsFactor: {
        value: 1,
        setting: 'camera.zoomExtentsFactor',
        desc: 'Factor to apply to the bounding box before zooming to extents',
        type: 'number'
      },


















      autoRotationSpeed: {
        value: 0.0,
      },
      damping: {
        value: 0.1,
      },
      defaults: {
        perspective: {
          value: {
            position: {
              value: {
                x: 0, y: 0, z: 0
              },
            },
            target: {
              value: {
                x: 0, y: 0, z: 0
              },
            }
          },
        },
        orthographic: {
          value: {
            position: {
              value: {
                x: 0, y: 0, z: 0
              },
            },
            target: {
              value: {
                x: 0, y: 0, z: 0
              },
            }
          },
        }
      },
      enableAutoRotation: {
        value: false,
      },
      enableKeyPan: {
        value: false,
      },
      enablePan: {
        value: true,
      },
      enableRotation: {
        value: true,
      },
      enableZoom: {
        value: true,
      },
      fov: {
        value: 45,
      },
      keyPanSpeed: {
        value: 1,
      },
      restrictions: {
        position: {
          cube: {
            value: {
              min: {
                value: {
                  x: -Infinity, y: -Infinity, z: -Infinity
                },
              },
              max: {
                value: {
                  x: Infinity, y: Infinity, z: Infinity
                },
              }
            },
          },
          sphere: {
            value: {
              center: {
                value: {
                  x: 0, y: 0, z: 0
                },
              },
              radius: {
                value: Infinity,
              }
            },
          }
        },
        target: {
          cube: {
            value: {
              min: {
                value: {
                  x: -Infinity, y: -Infinity, z: -Infinity
                },
              },
              max: {
                value: {
                  x: Infinity, y: Infinity, z: Infinity
                },
              }
            },
          },
          sphere: {
            value: {
              center: {
                value: {
                  x: 0, y: 0, z: 0
                },
              },
              radius: {
                value: Infinity,
              }
            },
          }
        },
        rotation: {
          value: {
            minPolarAngle: {
              value: 0,
            },
            maxPolarAngle: {
              value: 180,
            },
            minAzimuthAngle: {
              value: -Infinity,
            },
            maxAzimuthAngle: {
              value: Infinity,
            }
          },
        },
        zoom: {
          value: {
            minDistance: {
              value: 0,
            },
            maxDistance: {
              value: Infinity,
            }
          },
        }
      },
      rotationSpeed: {
        value: 0.5,
      },
      panSpeed: {
        value: 0.5,
      },
      type: {
        value: cameraViewTypes.PERSPECTIVE,
      },
      zoomSpeed: {
        value: 1.0,
      },
    },
    lights: {
      helper: {
        value: false,
        setting: 'lights.helpers',
        desc: 'Show / hide the light helpers',
        type: 'boolean'
      },
      lightScene: {
        value: 'default',
        setting: 'lights.lightScene',
      },
      lightScenes: {
        value: {},
        setting: 'lights.lightScenes',
      }
    },
    material: {
      environmentMapAsBackground: {
        value: false,
        setting: 'material.environmentMapAsBackground',
        desc: 'Show / hide the environment map in the background',
        type: 'boolean'
      },
      environmentMap: {
        value: 'none',
        setting: 'material.environmentMap',
        desc: 'Name of the environment map to use, or an array of 6 image URLs making up the cube mapped environment map (px, nx, pz, nz, py, ny)',
        type: (v) => (typeof v === 'string' || (GLOBAL_UTILS.isArrayOfType(v, 'string') && v.length === 6))
      },
      environmentMapResolution: {
        value: '1024',
        setting: 'material.environmentMapResolution',
        desc: 'Image resolution to be used for the named environment maps (available resolutions: 256, 512, 1024)',
        type: (v) => (['256', '512', '1024', '2048'].includes(v))
      }
    }
  };
  function _hasSubValue(def) {
    if (typeof def === 'object') {
      for (let key in def) {
        if (key === 'value')
          return true;
        if (_hasSubValue(def[key]))
          return true;
      }
    }

    return false;
  }

  function _convert(def, global) {
    for (let key in def) {
      if (key === 'value') {
        if (_hasSubValue(def[key])) {
          return global = _convert(def[key], global);
        } else {
          return def[key];
        }
      } else if (key !== 'desc') {
        global[key] = {};
        global[key] = _convert(def[key], global[key]);
      }
    }
    return global;
  }

  let defaultSettings = {};
  _convert(settingsDefinition, defaultSettings);

  threeDManagerConstants = {
    settingsDefinition: settingsDefinition,
    defaultSettings: defaultSettings,
    loggingLevels: LOGGING_LEVELS,
    cameraViewTypes: cameraViewTypes
  };
}

module.exports = require('../../shared/util/GlobalUtils').deepCopy(threeDManagerConstants);
