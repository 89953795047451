
let ThreeDManagerHelpers = function (___settings, ___handlers) {
  const GLOBAL_UTILS = require('../../../shared/util/GlobalUtils'),
        PATH_UTILS = ___settings.pathUtils,
        THREE = require('../../../externals/three'),
        TWEEN = require('@tweenjs/tween.js'),
        MESSAGING_CONSTANTS = require('../../../shared/constants/MessagingConstants'),
        MESSAGE_PROTOTYPE = require('../../../shared/messages/MessagePrototype'),
        LIVE_TRANSFORMATION_ID = 'liveTransformationEvent',
        _handlers = ___handlers,
        _scene = ___settings.scene,
        _geometryNode = ___settings.geometryNode;

  let that,
      _sceneObjects = new THREE.Object3D(),
      _viewportObjects = new THREE.Object3D(),
      _meshMaterialObjects = [],
      _meshes = [],
      _materials = [],
      _anchors = [],
      _hiddenPaths = [],
      _initialized = false,
      _showSceneObjects = true,
      _showViewportObjects = true;

  class ThreeDManagerHelpers {

    constructor() {
      that = this;
      _sceneObjects.visible = false;
      _scene.add(_sceneObjects);
      _viewportObjects.visible = false;
      _scene.add(_viewportObjects);
    }

    _createTransformationObject(group, transformation) {
      transformation.pivot = transformation.pivot ? new THREE.Vector3(transformation.pivot.x, transformation.pivot.y, transformation.pivot.z) : null;

      let tween;
      if(transformation.type === 'rotation') {
        let rotation = { degree: 0 };
        let rotAxis = new THREE.Vector3(transformation.rotationAxis.x, transformation.rotationAxis.y, transformation.rotationAxis.z).normalize();

        tween = new TWEEN.Tween(rotation, group).to({ degree: transformation.rotationDegree * Math.PI / 180 }, transformation.duration);
        if(transformation.parent && transformation.pivot) {
          tween.onUpdate(function() {
            let m = new THREE.Matrix4(),
                p = new THREE.Matrix4(),
                pivotMatrix = new THREE.Matrix4();
            let parent = PATH_UTILS.getPathObject(_geometryNode, transformation.parent);
            if(parent) {
              parent.updateMatrix(true);
              parent.updateMatrixWorld(true);
              p = parent.matrixWorld;
            }
            pivotMatrix.makeTranslation(transformation.pivot.x, transformation.pivot.y, transformation.pivot.z);
            m.multiply(pivotMatrix);
            m.multiply(new THREE.Matrix4().makeRotationAxis(rotAxis, rotation.degree));
            m.multiply(new THREE.Matrix4().getInverse(pivotMatrix));
            tween.transformationMatrix = p.multiply(m);
          });
        } else if(transformation.parent) {
          tween.onUpdate(function() {
            let m = new THREE.Matrix4(),
                p = new THREE.Matrix4();
            let parent = PATH_UTILS.getPathObject(_geometryNode, transformation.parent);
            if(parent) {
              parent.updateMatrix(true);
              parent.updateMatrixWorld(true);
              p = parent.matrixWorld;
            }
            m.multiply(new THREE.Matrix4().makeRotationAxis(rotAxis, rotation.degree));
            tween.transformationMatrix = p.multiply(m);
          });
        } else if(transformation.pivot) {
          tween.onUpdate(function() {
            let m = new THREE.Matrix4(),
                pivotMatrix = new THREE.Matrix4();
            pivotMatrix.makeTranslation(transformation.pivot.x, transformation.pivot.y, transformation.pivot.z);
            m.multiply(pivotMatrix);
            m.multiply(new THREE.Matrix4().makeRotationAxis(rotAxis, rotation.degree));
            m.multiply(new THREE.Matrix4().getInverse(pivotMatrix));
            tween.transformationMatrix = m;
          });
        } else {
          tween.onUpdate(function() {
            let m = new THREE.Matrix4();
            m.multiply(new THREE.Matrix4().makeRotationAxis(rotAxis, rotation.degree));
            tween.transformationMatrix = m;
          });
        }


      } else if (transformation.type === 'translation') {
        let translation = { x: 0, y: 0, z: 0 };

        tween = new TWEEN.Tween(translation, group).to({ x: transformation.translationVector.x, y: transformation.translationVector.y, z: transformation.translationVector.z }, transformation.duration);
        if (transformation.parent) {
          tween.onUpdate(function () {
            let m = new THREE.Matrix4(),
                p = new THREE.Matrix4();
            let parent = PATH_UTILS.getPathObject(_geometryNode, transformation.parent);
            if (parent) {
              parent.updateMatrix(true);
              parent.updateMatrixWorld(true);
              p = parent.matrixWorld;
            }
            m.makeTranslation(translation.x, translation.y, translation.z);
            tween.transformationMatrix = p.multiply(m);
          });
        } else {
          tween.onUpdate(function () {
            let m = new THREE.Matrix4();
            m.makeTranslation(translation.x, translation.y, translation.z);
            tween.transformationMatrix = m;
          });
        }


      } else if (transformation.type === 'scaling') {
        let scale = { x: 1, y: 1, z: 1 };

        tween = new TWEEN.Tween(scale, group).to({ x: transformation.scalingVector.x, y: transformation.scalingVector.y, z: transformation.scalingVector.z }, transformation.duration);

        if (transformation.parent && transformation.pivot) {
          tween.onUpdate(function () {
            let m = new THREE.Matrix4(),
                p = new THREE.Matrix4(),
                pivotMatrix = new THREE.Matrix4();

            let parent = PATH_UTILS.getPathObject(_geometryNode, transformation.parent);
            if (parent) {
              parent.updateMatrix(true);
              parent.updateMatrixWorld(true);
              p = parent.matrixWorld;
            }
            pivotMatrix.makeTranslation(transformation.pivot.x, transformation.pivot.y, transformation.pivot.z);
            m.multiply(pivotMatrix);
            m.multiply(new THREE.Matrix4().makeScale(scale.x, scale.y, scale.z));
            m.multiply(new THREE.Matrix4().getInverse(pivotMatrix));
            tween.transformationMatrix = p.multiply(m);
          });
        } else if (transformation.parent) {
          tween.onUpdate(function () {
            let m = new THREE.Matrix4(),
                p = new THREE.Matrix4();

            let parent = PATH_UTILS.getPathObject(_geometryNode, transformation.parent);
            if (parent) {
              parent.updateMatrix(true);
              parent.updateMatrixWorld(true);
              p = parent.matrixWorld;
            }
            m.multiply(new THREE.Matrix4().makeScale(scale.x, scale.y, scale.z));
            tween.transformationMatrix = p.multiply(m);
          });
        } else if (transformation.pivot) {
          tween.onUpdate(function () {
            let m = new THREE.Matrix4(),
                pivotMatrix = new THREE.Matrix4();

            pivotMatrix.makeTranslation(transformation.pivot.x, transformation.pivot.y, transformation.pivot.z);
            m.multiply(pivotMatrix);
            m.multiply(new THREE.Matrix4().makeScale(scale.x, scale.y, scale.z));
            m.multiply(new THREE.Matrix4().getInverse(pivotMatrix));
            tween.transformationMatrix = m;
          });
        } else {
          tween.onUpdate(function () {
            let m = new THREE.Matrix4();;
            m.multiply(new THREE.Matrix4().makeScale(scale.x, scale.y, scale.z));
            tween.transformationMatrix = m;
          });
        }
      }

      tween.transformationMatrix = new THREE.Matrix4();

      tween.hasParent = false;
      if(transformation.parent)
        tween.hasParent = true;

      tween.gloablDuration = transformation.delay + transformation.duration;
      if(transformation.repeat)
        tween.gloablDuration += tween.gloablDuration * transformation.repeat;

      if(transformation.repeat)
        tween.repeat(transformation.repeat);

      if(transformation.repeat && transformation.yoyo)
        tween.yoyo( true );

      tween.onComplete(function() {
        tween.stop();
      });

      tween.delay(transformation.delay);
      tween.easing(transformation.easing);

      return tween;
    }


    /**
     * Given an interaction data message, create an Event and dispatch it via the DOM.
     *
     * @param {module:MESSAGING_CONSTANTS~InteractionDataMessage} idm - interaction data message
     */
    dispatchEvent(idm) {
      let evt = new CustomEvent(idm.type);
      for (let k of idm) {
        evt[k] = idm[k];
      }
      _handlers.renderingHandler.getDomElement().dispatchEvent(evt);
      return evt;
    }

    initialize(){
      _initialized = true;
    }

    isInitialized() {
      return _initialized;
    }

    addMesh(mesh, material, properties) {
      _meshes.push(mesh);
      _materials.push(material);
      _meshMaterialObjects.push({ mesh: mesh, material: material, properties: properties });
    }

    removeMesh(mesh) {
      for (let i = 0, len = _meshMaterialObjects.length; i < len; i++) {
        if (mesh === _meshMaterialObjects[i].mesh) {
          let mat = _materials[i];
          _meshes.splice(i, 1);
          _materials.splice(i, 1);
          _meshMaterialObjects.splice(i, 1);



          if(mat) {
            ['alphaMap', 'aoMap', 'bumpMap', 'displacementMap', 'emissiveMap',
              'lightMap', 'map', 'metalnessMap', 'normalMap', 'roughnessMap',
              'rgbMap', 'additionalMaps0', 'additionalMaps1', 'additionalMaps2', 'additionalMaps3', 'additionalMaps4',
              'reflectionNormalMap', 'dispersionMap', 'reflectionMap'].forEach(function (attr) {
              if (mat[attr] && mat[attr].dispose)
                mat[attr].dispose();

            });
            mat.dispose();
          }

          return;
        }
      }
    }

    addAnchor(object, properties) {
      let anchor = {
        object: object,
        properties: Object.assign({
          lastPos: new THREE.Vector2(),
        }, properties)
      };
      _anchors.push(anchor);

      let msg_obj = {
        type: MESSAGING_CONSTANTS.messageTopics.SCENE_ANCHOR_ADD,
        viewportRuntimeId: _handlers.threeDManager.runtimeId,
        anchors: [],
      };

      let width = _handlers.renderingHandler.getSize().width;
      let height = _handlers.renderingHandler.getSize().height;
      let camera = _handlers.cameraHandler.camera;
      let canvas = _handlers.renderingHandler.getCanvas();
      let canvasPageCoordinates = canvas.getBoundingClientRect(),
          overflow = false;
      let screenVector = new THREE.Vector3(),
          pos = new THREE.Vector3();
      let raycaster = new THREE.Raycaster();

      object.updateMatrixWorld(true);

      screenVector.setFromMatrixPosition(object.matrixWorld);
      pos.set(screenVector.x, screenVector.y, screenVector.z);
      pos.project(camera);

      pos.x = ( pos.x * (width / 2) ) + (width / 2);
      pos.y = - ( pos.y * (height / 2) ) + (height / 2);

      pos.x = pos.x < 0 ? Math.ceil(pos.x) : Math.floor(pos.x);
      pos.y = pos.y < 0 ? Math.ceil(pos.y) : Math.floor(pos.y);

      if (pos.x <= 1 || pos.x >= width - 1 || pos.y <= 1 || pos.y >= height - 1)
        overflow = true;

      // take care of correction by device pixel ratio
      pos.xPixel = pos.x / devicePixelRatio;
      pos.yPixel = pos.y / devicePixelRatio;

      raycaster.ray.direction.copy(screenVector);
      raycaster.ray.origin.set(0, 0, 0);
      camera.localToWorld(raycaster.ray.origin);
      raycaster.ray.direction.sub(raycaster.ray.origin);

      let distance = raycaster.ray.direction.length();
      raycaster.ray.direction.normalize();

      let closestIntersectionDistance = Number.MAX_VALUE;
      _scene.traverseVisible(function(obj) {
        if(obj instanceof THREE.Mesh) {
          let curIntersections = raycaster.intersectObject(obj);
          if (curIntersections.length)
            if (curIntersections[0].distance < closestIntersectionDistance)
              closestIntersectionDistance = curIntersections[0].distance;
        }
      });

      camera.getWorldDirection(screenVector);
      let viewingAngle = raycaster.ray.direction.dot(screenVector);

      let anchorData = {
        location: {
          x: object.position.x,
          y: object.position.y,
          z: object.position.z,
        },
        containerX: pos.xPixel,
        containerY: pos.yPixel,
        containerWidth: width / devicePixelRatio,
        containerHeight: height / devicePixelRatio,
        clientX: pos.xPixel + canvasPageCoordinates.left,
        clientY: pos.yPixel + canvasPageCoordinates.top,
        pageX: pos.xPixel + canvasPageCoordinates.left + window.pageXOffset,
        pageY: pos.yPixel + canvasPageCoordinates.top + window.pageYOffset,
        viewport: _handlers.threeDManager.runtimeId,
        hidden: closestIntersectionDistance < distance,
        overflow: overflow,
        distance: distance,
        viewingAngle: viewingAngle,
        scenePath: properties.path,
        format: properties.format,
        data: properties.data,
      };

      msg_obj.anchors.push(Object.assign(anchorData, {
        updateFunction: update => anchor.properties.update = update
      }));

      let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_ANCHORDATA, msg_obj);
      _handlers.threeDManager.message(msg_obj.type, m);
      that.dispatchEvent(msg_obj);
    }

    removeAnchor(object) {
      let anchor, properties;
      for (let i = 0, len = _anchors.length; i < len; i++) {
        if (object === _anchors[i].object) {
          anchor = _anchors[i];
          properties = anchor.properties;

          let msg_obj = {
            type: MESSAGING_CONSTANTS.messageTopics.SCENE_ANCHOR_REMOVE,
            viewportRuntimeId: _handlers.threeDManager.runtimeId,
            anchors: [],
          };

          let width = _handlers.renderingHandler.getSize().width;
          let height = _handlers.renderingHandler.getSize().height;
          let camera = _handlers.cameraHandler.camera;
          let canvas = _handlers.renderingHandler.getCanvas();
          let canvasPageCoordinates = canvas.getBoundingClientRect(),
              overflow = false;
          let screenVector = new THREE.Vector3(),
              pos = new THREE.Vector3();
          let raycaster = new THREE.Raycaster();

          object.updateMatrixWorld(true);

          screenVector.setFromMatrixPosition(object.matrixWorld);
          pos.set(screenVector.x, screenVector.y, screenVector.z);
          pos.project(camera);

          pos.x = ( pos.x * (width / 2) ) + (width / 2);
          pos.y = - ( pos.y * (height / 2) ) + (height / 2);

          pos.x = pos.x < 0 ? Math.ceil(pos.x) : Math.floor(pos.x);
          pos.y = pos.y < 0 ? Math.ceil(pos.y) : Math.floor(pos.y);

          if (pos.x <= 1 || pos.x >= width - 1 || pos.y <= 1 || pos.y >= height - 1)
            overflow = true;

          // take care of correction by device pixel ratio
          pos.xPixel = pos.x / devicePixelRatio;
          pos.yPixel = pos.y / devicePixelRatio;

          raycaster.ray.direction.copy(screenVector);
          raycaster.ray.origin.set(0, 0, 0);
          camera.localToWorld(raycaster.ray.origin);
          raycaster.ray.direction.sub(raycaster.ray.origin);

          let distance = raycaster.ray.direction.length();
          raycaster.ray.direction.normalize();

          let closestIntersectionDistance = Number.MAX_VALUE;
          _scene.traverseVisible(function(obj) {
            if(obj instanceof THREE.Mesh) {
              let curIntersections = raycaster.intersectObject(obj);
              if (curIntersections.length)
                if (curIntersections[0].distance < closestIntersectionDistance)
                  closestIntersectionDistance = curIntersections[0].distance;
            }
          });

          camera.getWorldDirection(screenVector);
          let viewingAngle = raycaster.ray.direction.dot(screenVector);

          let anchorData = {
            location: {
              x: object.position.x,
              y: object.position.y,
              z: object.position.z,
            },
            containerX: pos.xPixel,
            containerY: pos.yPixel,
            containerWidth: width / devicePixelRatio,
            containerHeight: height / devicePixelRatio,
            clientX: pos.xPixel + canvasPageCoordinates.left,
            clientY: pos.yPixel + canvasPageCoordinates.top,
            pageX: pos.xPixel + canvasPageCoordinates.left + window.pageXOffset,
            pageY: pos.yPixel + canvasPageCoordinates.top + window.pageYOffset,
            viewport: _handlers.threeDManager.runtimeId,
            hidden: closestIntersectionDistance < distance,
            overflow: overflow,
            distance: distance,
            viewingAngle: viewingAngle,
            scenePath: properties.path,
            format: properties.format,
            data: properties.data,
          };

          msg_obj.anchors.push(anchorData);

          let m = new MESSAGE_PROTOTYPE(MESSAGING_CONSTANTS.messageDataTypes.SCENE_ANCHORDATA, msg_obj);
          _handlers.threeDManager.message(msg_obj.type, m);
          that.dispatchEvent(msg_obj);

          _anchors.splice(i, 1);
          return;
        }
      }
    }

    getMeshes() {
      return _meshes;
    }

    getMaterials() {
      return _materials;
    }

    getMeshMaterialObjects() {
      return _meshMaterialObjects;
    }

    getAnchors() {
      return _anchors;
    }

    toggleMeshes(toggle) {
      for (let i = 0, len = _meshMaterialObjects.length; i < len; i++)
        if (toggle)
          _meshMaterialObjects[i].mesh.material = _meshMaterialObjects[i].material;
    }

    togglePointSize(toggle) {
      _geometryNode.traverse(function (o) {
        if (o.hasOwnProperty('material') && o instanceof THREE.Points) {
          o.material.size = toggle ? _handlers.threeDManager.getSetting('render.pointSize') : 1;
        }
      });
    }

    toggleGeometry(show, hide) {
      for (let i = 0; i < show.length; i++) {
        if (GLOBAL_UTILS.typeCheck(show[i], 'string')) {
          let index = _hiddenPaths.indexOf(show[i]);
          if (index > -1) _hiddenPaths.splice(index, 1);
        }
      }

      for (let i = 0; i < hide.length; i++) {
        if (GLOBAL_UTILS.typeCheck(hide[i], 'string'))
          if (!_hiddenPaths.includes(hide[i]))
            _hiddenPaths.push(hide[i]);
      }

      _handlers.renderingHandler.updateShadowMap();
    }

    toggleHiddenGeometry(toggle) {
      for (let i = 0; i < _hiddenPaths.length; i++) {
        let obj = PATH_UTILS.getPathObject(_geometryNode, _hiddenPaths[i]);
        if (obj) {
          obj.visible = !toggle;
          obj.hiddenObject = toggle;
        }
      }
    }

    addSceneObject(obj) {
      _sceneObjects.add(obj);
    }

    removeSceneObject(obj) {
      _sceneObjects.remove(obj);
    }

    setLiveTransformation(group, scenePaths, transformations, reset, duration) {
      let transformationGroup = {};
      transformationGroup.reset = reset;
      transformationGroup.duration = duration;
      let sceneObjects = [];
      for(let i = 0, len = scenePaths.length; i < len; i++) {
        let obj = PATH_UTILS.getPathObject(_geometryNode, scenePaths[i]);
        if(obj) sceneObjects.push(obj);
      }

      transformationGroup.sceneObjects = sceneObjects;
      transformationGroup.sceneObjectPaths = scenePaths;

      let tweens = [];
      transformationGroup.maxDuration = 0;
      transformationGroup.hasParent = false;

      for (let j = 0, len2 = transformations.length; j < len2; j++) {
        let t = that._createTransformationObject(group, transformations[j]);
        transformationGroup.maxDuration = Math.max(transformationGroup.maxDuration, t.gloablDuration);
        transformationGroup.hasParent = transformationGroup.hasParent || t.hasParent;
        tweens.push(t);
      }

      if(transformationGroup.maxDuration === transformationGroup.duration)
        transformationGroup.duration = Infinity;

      transformationGroup.tweens = tweens;
      transformationGroup.start = function() {
        for (let k = 0, len3 = tweens.length; k < len3; k++)
          tweens[k].start();
      };

      transformationGroup.prev = new THREE.Matrix4();
      transformationGroup.promise = new Promise(function(resolve, reject) {
        transformationGroup.stopTransformation = function() {
          _handlers.renderingHandler.unregisterForContinuousRendering(LIVE_TRANSFORMATION_ID);
          if(_handlers.renderingHandler.containsContinuousRendering(LIVE_TRANSFORMATION_ID) === false) {
            // only if there are no other transformations active
            _handlers.renderingHandler.stopUpdatingShadowMap();
            _handlers.renderingHandler.updateShadowMap();
          }
          transformationGroup.stopped = true;
          resolve(true);
        };
      });

      transformationGroup.stopCalled = false;
      transformationGroup.stopAndReset = false;
      transformationGroup.stop = function(reset) {
        transformationGroup.stopCalled = true;
        transformationGroup.stopAndReset = reset;
      };


      _handlers.renderingHandler.keepUpdatingShadowMap();
      _handlers.renderingHandler.registerForContinuousRendering(LIVE_TRANSFORMATION_ID);
      _handlers.threeDManager.transformationGroups.push(transformationGroup);
      return transformationGroup;
    }

    toggleSceneObjects(toggle) {
      _showSceneObjects = toggle;
      if(!toggle) _sceneObjects.visible = toggle;
    }

    addViewportObject(obj) {
      _viewportObjects.add(obj);
    }

    removeViewportObject(obj) {
      _viewportObjects.remove(obj);
    }

    toggleViewportObjects(toggle) {
      _showViewportObjects = toggle;
      if(!toggle) _viewportObjects.visible = toggle;
    }

    toggleSceneBackground(toggle) {
      if(_handlers.materialHandler.getSceneBackground)
        _scene.background = toggle ? _handlers.materialHandler.getSceneBackground() : null;
    }

    toggleViewportMatrix(toggle) {
      _geometryNode.applyMatrix(toggle ?
        _handlers.threeDManager.transformationMatrix :
        new THREE.Matrix4().getInverse(_handlers.threeDManager.transformationMatrix, true) );
      _viewportObjects.applyMatrix(toggle ?
        _handlers.threeDManager.transformationMatrix :
        new THREE.Matrix4().getInverse(_handlers.threeDManager.transformationMatrix, true) );
    }

    toggleLiveTransformations(toggle, time) {
      let transformedObjects = [];
      if(toggle) {
        // in this case the live transformations get activated

        for(let i = 0; i < _handlers.threeDManager.transformationGroups.length; i++) {
          let g = _handlers.threeDManager.transformationGroups[i];
          if(g.stopped) continue;

          if(!g.initTime) g.initTime = time;
          let tweens = g.tweens;
          let sceneObjects = g.sceneObjects;
          let m = new THREE.Matrix4();

          // update all the tweens and see if they are still active
          let tweenObjectActive = false;
          for(let j = 0, len2 = tweens.length; j < len2; j++) {
            let tween = tweens[j];
            tween.update(time);
            m.multiply(tween.transformationMatrix);

            if(tween.isPlaying()) tweenObjectActive = true;
          }
          g.active = !g.stopCalled && tweenObjectActive && !(time > g.duration + g.initTime);
          // apply the transformations to the according scene objects
          for(let j = 0, len2 = sceneObjects.length; j < len2; j++){
            if(!sceneObjects[j]) continue;
            if(!sceneObjects[j].parent) {
              let obj = PATH_UTILS.getPathObject(_geometryNode, g.sceneObjectPaths[j]);
              sceneObjects[j] = obj;
            }
            sceneObjects[j].applyMatrix(m);
            if(!transformedObjects.includes(sceneObjects[j]))
              transformedObjects.push(sceneObjects[j]);
          }
          g.prev.getInverse(m);
        }



      } else {

        // in this case the live transformations get deactivated
        for(let i = 0; i < _handlers.threeDManager.transformationGroups.length; i++) {
          let g = _handlers.threeDManager.transformationGroups[i];
          if(g.stopped) continue;
          let sceneObjects = g.sceneObjects;

          // see if the current group should be stopped
          let waitWithEnd = false;
          if(g.active === false) {

            // if the current group is not active any more, test if a group that was initialized before it and has the same length is still active
            // if this is the case, wait with stopping one cycle to account for parenting matrices
            for(let j = 0; j < _handlers.threeDManager.transformationGroups.length; j++) {
              if(_handlers.threeDManager.transformationGroups[j].maxDuration === g.maxDuration
                && _handlers.threeDManager.transformationGroups[j].initTime === g.initTime
                && j < i
                && _handlers.threeDManager.transformationGroups[j].stopped !== true)
                waitWithEnd = true;

            }
          }

          if(g.active === false && waitWithEnd === false) {
            // deactivate the group
            if(time > g.duration + g.initTime || g.stopCalled) {
              let tweens = g.tweens;
              // stop the tweens
              for(let j = 0, len2 = tweens.length; j < len2; j++) {
                tweens[j].stop();
              }
            }


            for(let j = 0, len2 = sceneObjects.length; j < len2; j++){
              if(!sceneObjects[j]) continue;
              let identity = new THREE.Matrix4();
              if((g.reset === true && g.stopCalled !== true) || g.stopAndReset === true) {
                sceneObjects[j].applyMatrix(g.prev);
              } else {
                sceneObjects[j].updateMatrix(true);
                sceneObjects[j].updateMatrixWorld(true);

                // if the end result is close to an identity matrix, set it to an identity matrix ( precision correction )
                let isIdentity = true;
                let eps = 0.001;
                for(let k = 0; k < 16; k++) {
                  if(!(identity.elements[k] - eps < sceneObjects[j].matrix.elements[k] && sceneObjects[j].matrix.elements[k] < identity.elements[k] + eps))
                    isIdentity = false;
                }
                if(isIdentity) {
                  sceneObjects[j].matrix.identity();
                  sceneObjects[j].matrixWorld.identity();
                  sceneObjects[j].updateMatrix(true);
                  sceneObjects[j].updateMatrixWorld(true);
                }

              }
              if(!transformedObjects.includes(sceneObjects[j]))
                transformedObjects.push(sceneObjects[j]);
            }

            // really stop the group
            g.stopTransformation();
          } else {
            // apply the inverse matrix to reset the objects
            for(let j = 0, len2 = sceneObjects.length; j < len2; j++){
              if(!sceneObjects[j]) continue;
              sceneObjects[j].applyMatrix(g.prev);
              if(!transformedObjects.includes(sceneObjects[j]))
                transformedObjects.push(sceneObjects[j]);
            }
          }
        }
      }
      return transformedObjects;
    }

    toggleViewport(toggle, time) {

      if(_showViewportObjects) _viewportObjects.visible = toggle;
      if(_showSceneObjects) _sceneObjects.visible = toggle;

      this.toggleHiddenGeometry(toggle);
      this.toggleMeshes(toggle);
      this.togglePointSize(toggle);
      this.toggleSceneBackground(toggle);
      this.toggleViewportMatrix(toggle);
      if(time) this.toggleLiveTransformations(toggle, time);
    }

    destroyViewport() {
      _meshes.length = 0;
      _materials.length = 0;
      _meshMaterialObjects.length = 0;
      _anchors.length = 0;
      _scene.remove(_sceneObjects);
      _scene.remove(_viewportObjects);
    }
  }

  return new ThreeDManagerHelpers();
};

module.exports = ThreeDManagerHelpers;
