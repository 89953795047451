"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ControlParameterType;
(function (ControlParameterType) {
    ControlParameterType["STRING"] = "string";
    ControlParameterType["CHECKBOX"] = "checkbox";
    ControlParameterType["VECTOR3"] = "vector3";
    ControlParameterType["BUTTON"] = "button";
    ControlParameterType["CHECKLIST"] = "checklist";
    ControlParameterType["GROUP"] = "group";
    ControlParameterType["COLOR"] = "color";
    ControlParameterType["DROPDOWN"] = "dropdown";
    ControlParameterType["FILE"] = "file";
    ControlParameterType["MODAL"] = "modal";
    ControlParameterType["SLIDER"] = "slider";
    ControlParameterType["STATICHTML"] = "statichtml";
})(ControlParameterType = exports.ControlParameterType || (exports.ControlParameterType = {}));
